import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { AppProvider } from "../store/store";
import GameRoot from './game/GameRoot';
import Home from "./Home";
import Join from "./Join";
import NotFound from "./NotFound";
import KioskGameInfo from "./kiosk/KioskGameInfo";
import { useEffect } from "react";
import KioskRoot from "./kiosk/KioskRoot";

const ScrollToTop = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>
};

function App() {
  return <AppProvider>
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/join/:gameId" element={<Join />} />
          <Route path="/game" element={<GameRoot />} />
          <Route path="/kiosk" element={<KioskRoot />} />
        </Routes>
      </ScrollToTop>
    </Router>
  </AppProvider>
}

export default App;
