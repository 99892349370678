import { voiceSectionMetadata } from "../../text-util"
import { teamColors } from "../../theme"
import Silhouette from "../Silhouette"

const KioskIntroSteps = (props: { introStepIndex: number }) => {
  const renderGameIntro = () => {
    return <div style={{textAlign: "center", width: "50%" }}>
      <h1>Vem ska Märit bli?<br></br>Det bestämmer ni!</h1>
      <p>
        Märit föds år 1878 och växer upp här i Uddevalla.
        Hennes föräldrar jobbar på tändssticksfabriken.
        Hon kommer att få se hela världen förändras,
        och hennes liv kommer att vara fullt av möjligheter.
      </p>
      <p>
        Men hon har tre inre röster som vill gå olika vägar.
      </p>
      <h3>Ni är de tre rösterna.</h3>
        <Silhouette width={500} style={{position: "absolute", top: 340, left: -120, transform: "rotate(24deg)",  mixBlendMode: "multiply"}} color={teamColors[2]} />
        <Silhouette width={500} style={{position: "absolute", bottom: -80, right: -80, transform: "rotate(-120deg)", mixBlendMode: "multiply"}} color={teamColors[1]} />
        <Silhouette width={500} style={{position: "absolute", top: -340, right: 140, transform: "rotate(-30deg)", mixBlendMode: "multiply"}} color={teamColors[0]} />
    </div>
  }
  const renderVoiceInfo = () => {
    return <div style={{textAlign: "center", width: "95%", display: "flex", justifyContent: "center", alignItems: "center"}}>
      { [...voiceSectionMetadata].reverse().map((m, index) => {
        return <div key={index} style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Silhouette width={300} style={{mixBlendMode: "multiply"}} color={m.color} />
          <h2 style={{margin: "0px", marginTop: "40px", marginBottom: "6px"}}>{ m.colorName } rösten</h2>
          <div style={{ width: "90%" }}>
            säger <span style={{ padding: "4px 12px 4px 12px", backgroundColor: m.color, mixBlendMode: "multiply" }}><strong>{ m.keyword }</strong></span>. { m.description }
          </div>
        </div>
      }) }
    </div>
  }
  const renderYourVoice = () => {
    return <div style={{textAlign: "center", width: "50%"}}>
      <h1 style={{margin: 0}}>Vilken röst är du?</h1>
      <h3 style={{margin: 0, marginTop: "10px"}}>Titta på mobilen. Flytta till rätt plats.</h3>
      <div style={{position: "absolute", mixBlendMode: "multiply", left: "50%", top: "50%", transform: "translate(-50%,-50%)", display: "flex" }} >
        <Silhouette width={500} style={{ transform: "translate(230px, 0)", mixBlendMode: "multiply"}} color={teamColors[2]} />
        <Silhouette width={500} style={{ mixBlendMode: "multiply"}} color={teamColors[1]} />
        <Silhouette width={500} style={{ transform: "translate(-230px, 0)", mixBlendMode: "multiply"}} color={teamColors[0]} />
      </div>
    </div>
  }

  const renderContent = () => {
    switch (props.introStepIndex) {
      case 0:
        return renderGameIntro()
      case 1:
        return renderVoiceInfo()
      case 2:
        return renderYourVoice()
    }
    return null
  }

  return <div style={{ display: "flex", overflow: "hidden", alignItems: "center", justifyContent: "center", width: "100%", position: "relative" }}>
    { renderContent() }
  </div>
}

export default KioskIntroSteps;