import axios from "axios"
import { useEffect, useRef, useState } from "react"
import Spinner from "../Spinner"
import GavelHorizonal from './../../images/gavel_horizontal.png'
import { ReactComponent as GavelImage } from './../../images/gavel.svg';
import Button from "../Button"
import { palette } from "../../theme"
import { DrumPadManager } from "./KioskRoot"
import { IGameState } from "../../api-types"
import TimeoutIcon from "./TimeoutIcon"
import { appConfig } from "../../app-config"

enum GUIState {
  SelectPlayerCount = "SelectPlayerCount",
  ConfirmStart = "ConfirmStart",
  WaitingToStart = "WaitingToStart"
}

const playerCountSelectionInterval = 1500
const playerCounts = [3, 6, 9]

const KioskStartGame = (props: {
  drumPadManager: DrumPadManager,
  onGameStarted: (gameState: IGameState) => void
  onGameStartError: (error: string) => void
  onCancel: () => void
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [guiState, setGuiState] = useState(GUIState.SelectPlayerCount)
  const [playerCountIndexDisplay, setPlayerCountIndexDisplay] = useState(0)
  const [timeoutSecondsLeft, setTimeoutSecondsLeft] = useState(0)

  const guiStateActual = useRef(GUIState.SelectPlayerCount);
  const cancelCountdownHandle = useRef<any>(null)
  const playerCountTimerHandle = useRef<any>(null)
  const playerCountIndex = useRef(0)

  useEffect(() => {
    props.drumPadManager.onDrumPadHit = onDrumPadHit
    console.log("KioskStartGame mounted")

    transitionToGuiState(GUIState.SelectPlayerCount)

    return () => {
      console.log("KioskStartGame unmounted")
      clearInterval(playerCountTimerHandle.current)
      clearInterval(cancelCountdownHandle.current)
    }
  }, [])

  useEffect(() => {
    if (timeoutSecondsLeft < 0) {
      props.onCancel()
    }
  }, [timeoutSecondsLeft])

  const startNewGame = (playerCount: number) => {
    transitionToGuiState(GUIState.WaitingToStart)
    axios.post("/games", { playerCount })
      .then((response) => {
        const gameState = response.data.gameState
        props.onGameStarted(gameState)
      })
      .catch((error) => {
        props.onGameStartError(error.message)
      })
  }

  const resetCancelTimeout = () => {
    clearInterval(cancelCountdownHandle.current)
    cancelCountdownHandle.current = null
  }

  const setCancelTimeout = () => {
    resetCancelTimeout()
    setTimeoutSecondsLeft(0.001 * appConfig.kioskTimeoutIntervalMs)
    cancelCountdownHandle.current = setInterval(() => {
      setTimeoutSecondsLeft((prev) => prev - 0.001 * appConfig.kioskTimeoutDeltaMs)
    }, appConfig.kioskTimeoutDeltaMs)
  }

  const transitionToGuiState = (newGuiState: GUIState) => {
    switch (newGuiState) {
      case GUIState.SelectPlayerCount: {
        playerCountIndex.current = 0
        setPlayerCountIndexDisplay(playerCountIndex.current)
        playerCountTimerHandle.current = setInterval((e) => {
          console.log({ playerCountIndex })
          playerCountIndex.current = (playerCountIndex.current + 1) % playerCounts.length;
          setPlayerCountIndexDisplay(playerCountIndex.current)
        },
          playerCountSelectionInterval
        )

        setCancelTimeout()
        break
      }
      case GUIState.ConfirmStart: {
        setCancelTimeout()
        break
      }
      case GUIState.WaitingToStart: {
        resetCancelTimeout()
        break
      }
    }
    if (newGuiState != GUIState.SelectPlayerCount) {
      clearInterval(playerCountTimerHandle.current)
      playerCountTimerHandle.current = null
    }
    guiStateActual.current = newGuiState
    setGuiState(newGuiState)
  }

  const onDrumPadHit = () => {
    switch (guiStateActual.current) {
      case GUIState.SelectPlayerCount: {
        transitionToGuiState(GUIState.ConfirmStart)
        break
      }
      case GUIState.ConfirmStart: {
        startNewGame(playerCounts[playerCountIndex.current])
        break
      }
    }
  }

  const renderGavel = () => {
    return <div style={{position: "relative"}}>
      <img style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -120%) rotate(10deg)",
        mixBlendMode: "multiply",
        opacity: 0.8,
        width: "370px",
        marginBottom: "30px"
    }} src={GavelHorizonal} />
    </div>
  }

  const renderContentForGuiState = () => {
    switch (guiState) {
      case GUIState.SelectPlayerCount: {
        return <div style={{ width: "80%", textAlign: "center" }}>
          <div style={{ display: "flex", width: "100%", justifyContent: "space-evenly", marginTop: "60px" }}>
            {[0, 1, 2].map((index) => {
              const isActive = index == playerCountIndexDisplay
              return <div key={index}>
                <div style={{ visibility: isActive ? "inherit" : "hidden" }} >
                  {renderGavel()}
                </div>
                <h1 style={{
                  lineHeight: "200px",
                  margin: 0,
                  padding: "30px",
                  width: "200px",
                  height: "200px",
                  borderRadius: "300px",
                  backgroundColor: palette.text,
                  marginTop: "-10px",
                  fontSize: "150px",
                  color: palette.kioskBackground,
                  opacity: isActive ? 1 : 0.2
                }}>{playerCounts[index]}</h1>
              </div>
            })}
          </div>
          <h2 style={{ margin: 0, marginTop: "60px" }}>Välj antal spelare</h2>
        </div>
      }
      case GUIState.ConfirmStart: {
        return <div style={{ textAlign: "center" }}>
          <GavelImage style={{ width: "500px", marginBottom: "-30px", fill: palette.text, stroke: palette.kioskBackground }} />
          <h2>Slå igen för att starta <br></br>spel med {playerCounts[playerCountIndexDisplay]} spelare.</h2>
        </div>
      }
      case GUIState.WaitingToStart: {
        return <div style={{textAlign: "center"}}>
          <h2>Startar spelomgång...</h2>
          <Spinner />
        </div>
      }
    }
  }

  return <div style={{
    display: "flex",
    flex: 1,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  }}>
    {renderContentForGuiState()}
    <p className="error">{errorMessage}</p>
    <div style={{position: "absolute", bottom: "5%", visibility: guiState == GUIState.WaitingToStart ? "hidden" : "inherit"}}>
      <TimeoutIcon
        color={palette.dimmedLight}
        relTimeRemaining={timeoutSecondsLeft / (0.001 * appConfig.kioskTimeoutIntervalMs)}
      />
    </div>
  </div>
}

export default KioskStartGame