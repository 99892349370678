import { palette } from "../../theme"
import Spinner from "../Spinner"

const ProceedButtonOrSpinner = (props: { onProceed: () => void, showSpinner: boolean }) => {
  return <div style={{position: "relative" }}>
    <button style={{visibility: !props.showSpinner ? "inherit" : "hidden"}} className="button" onClick={() => {
      if (!props.showSpinner) {
        props.onProceed()
      }
    }}>Fortsätt</button>
    <div style={{ visibility: props.showSpinner ? "inherit" : "hidden", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }} >
      <Spinner />
      <div style={{width: "250px", marginTop: "-4px", opacity: palette.dimmedAlpha}}>Väntar på övriga spelare</div>
    </div>
  </div>
}

export default ProceedButtonOrSpinner