import { IGameState } from "../../api-types";
import { ILightsState } from "../../store/app-state";
import { GameStateUtil } from "../../store/game-state";

const low = 20 / 127
const mid = 50 / 127
const high = 127 / 127

export const lightsStateIdle: ILightsState = {
  lightIntensities: [0, 0, 0, 0]
}

export const lightsStatePreGame: ILightsState = {
  lightIntensities: [0, 0, 0, 1]
}

export const lightStateForGameState = (gameState: IGameState): ILightsState =>  {
  const gameIsCompleted = GameStateUtil.gameIsCompleted(gameState)
  const votingInProgress = GameStateUtil.votingIsInProgress(gameState)
  const allVotesCast = GameStateUtil.allVotesHaveBeenCast(gameState)
  const allPlayersArePresent = GameStateUtil.allPlayersArePresent(gameState)
  if (gameIsCompleted) {
    // Game completed
    return {
      lightIntensities: [high, high, high, 1]
    }
  } else if (!allPlayersArePresent) {
    // Waiting for players
    return {
      lightIntensities: [low, low, low, 1]
    }
  } else if (allVotesCast) {
    const hasWinner = GameStateUtil.maxRelVoteCount(gameState) > 0.5
    if (hasWinner) {
      // All votes cast and there is a winning option
      const winnerIndex = GameStateUtil.leadingOptionIndex(gameState)
      const intensities = [0, 0, 0, 0]
      intensities[winnerIndex] = 1
      return {
        lightIntensities: [intensities[0], intensities[1], intensities[2], 1]
      }
    } else {
      // All votes cast but there's no winning option
      return {
        lightIntensities: [0, 0, 0, 0]
      }
    }
  } else if (votingInProgress) {
    // Voting is in progress
    return {
      lightIntensities: [mid, mid, mid, 1]
    }
  } else {
    // Waiting for voting to start
    return {
      lightIntensities: [mid, mid, mid, 1]
    }
  }
}