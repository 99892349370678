import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from "react";
import { HeartbeatStatus } from "../../store/app-state";
import { GameStateContext, store } from "../../store/store";
import { clearGameState, setGameState, setHeartBeatStatus, setPlayerState, setQuitModalIsVisible as setQuitModalIsVisible } from '../../actions/actions';
import { IGameState, IPlayerState } from '../../api-types';
import { GameStateUtil } from '../../store/game-state';
import Lobby from './Lobby';
import Turn from './Turn';
import axios from 'axios';
import { appConfig } from '../../app-config';
import Modal from '../Modal';
import ConnectionWarning from './ConnectionWarning';
import { ReactComponent as CloseIcon } from './../../images/icon_cross.svg';
import { palette, teamColors } from '../../theme';

let heartBeatTimeoutHandle: any = undefined

const QuitButton = () => {
  const { dispatch } = useContext(store);
  return <button style={{
    border: "none",
    backgroundColor: palette.dimmedDark,
    opacity: 0.5,
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    margin: "14px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }} onClick={() => { dispatch(setQuitModalIsVisible(true)) }}>
    <CloseIcon />
  </button>
}

const QuitModal = () => {
  const navigate = useNavigate()
  const { dispatch } = useContext(store);
  return <Modal
    title="Vill du lämna spelet?"
    message="Du kan gå med igen genom att scanna spelomgångens QR-kod."
    confirmTitle="Ja"
    dismissTitle="Nej"
    onAction={(didConfirm) => {
      if (didConfirm) {
        dispatch(clearGameState())
        navigate("/")
      }
      dispatch(setQuitModalIsVisible(false))
    }} />
}

const GameRoot = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { state, dispatch } = useContext(store);
  const playerState = state.playerState
  const gameState = state.gameState
  const guiState = state.guiState

  const sendHeartbeat = (gameId: string, playerId: string) => {
    const startTime = Date.now()
    axios.put("games/" + gameId + "/heartbeat", { playerId })
      .then((response) => {
        const responseTime = Date.now() - startTime
        const didTimeOut = responseTime > appConfig.heartBeatMaxResponseTimeMs
        dispatch(setHeartBeatStatus(didTimeOut ? HeartbeatStatus.timeout : HeartbeatStatus.ok, responseTime))
        dispatch(setGameState(response.data as IGameState))
      })
      .catch((error) => {
        if (error.response && error.response.status == 404) {
          navigate("/", { state: { errorMessage: "Ogiltig spelomgång (" + error.message + ")" } })
        } else {
          dispatch(setHeartBeatStatus(HeartbeatStatus.error))
        }
      })
      .finally(() => {
        if (heartBeatTimeoutHandle != null) {
          heartBeatTimeoutHandle = setTimeout(() => {
            sendHeartbeat(gameId, playerId)
          }, appConfig.heartBeatIntervalMs)
        }
      })
  }

  useEffect(() => {
    console.log("GameRoot mounted")
    // console.log(location.state)

    // Init game state
    const state = location.state
    if (state) {
      // A game state was passed in the route
      const playerState = state.playerState
      const gameState = state.gameState
      dispatch(setPlayerState(playerState))
      dispatch(setHeartBeatStatus(HeartbeatStatus.ok))
      dispatch(setGameState(gameState))
      heartBeatTimeoutHandle = setTimeout(() => {
        sendHeartbeat(gameState.id, playerState.playerId)
      }, 0)

      // Clear route state so it's not available on reload
      window.history.replaceState({}, document.title)
    } else {
      // No game state passed in the route.
      navigate("/", { state: { errorMessage: "Ingen spelomgång inladdad" } })
    }
    return () => {
      console.log("GameRoot unmounted")
      clearTimeout(heartBeatTimeoutHandle)
      heartBeatTimeoutHandle = null
    }
  }, [])

  const renderDevInfo = () => {
    if (!appConfig.isProduction && appConfig.showDevInfo) {
      return <div style={{ pointerEvents: "none", color: "red", backgroundColor: "rgba(255, 0, 0, 0.1)", fontSize: "8px", position: "absolute", right: 0, bottom: 0 }}>
        <pre>{JSON.stringify(guiState, null, 2)}</pre>
        <pre>{JSON.stringify(gameState, null, 2)}</pre>
        <pre>{JSON.stringify(playerState, null, 2)}</pre>
      </div>
    }
    return null
  }

  const renderCurrentScreen = (gameState: IGameState, playerState: IPlayerState) => {
    if (state.guiState.heartbeatStatus != HeartbeatStatus.ok) {
      return <ConnectionWarning />
    } else if (!GameStateUtil.allPlayersArePresent(gameState) && !GameStateUtil.gameIsCompleted(gameState)) {
      return <Lobby gameState={gameState} playerState={playerState} />
    } else {
      return <Turn gameState={gameState} playerState={playerState} />
    }
  }

  const renderGame = () => {
    if (playerState != null && gameState != null) {
      return <GameStateContext.Provider value={{ gameState: gameState, playerState: playerState }}>
        {renderCurrentScreen(gameState, playerState)}
        {renderDevInfo()}
        <div style={{ position: "absolute", right: 0, top: 0 }}>
          <QuitButton />
        </div>
        {state.guiState.quitModalIsVisible ? <QuitModal /> : null}
      </GameStateContext.Provider>
    } else {
      return <div></div>
    }
  }

  let borderColor: string | null = null
  if (playerState != null) {
    if (gameState?.introStepIndex == null || gameState?.introStepIndex == 2) {
      borderColor = teamColors[playerState.teamIndex]
    }
  }
  const borderStyle = borderColor != null ? "7px solid " + borderColor : "none";

  return <div style={{
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
    border: borderStyle
  }}>
    {renderGame()}
  </div>
}

export default GameRoot;