import { useState } from "react";
import { palette } from "../../theme";
import NamedImage from "./NamedImage";

const InfoBox = (props: { title: string, image?: string, text: string, textColor?: string, imageMaxWidth?: string }) => {

  return <div style={{
    width: "100%"
  }}>
    <div style={{
      backgroundColor: palette.text,
      color: props.textColor ?? palette.background,
      marginBottom: "20px",
      padding: "20px"
    }}>
      <h2 style={{
        margin: 0,
        paddingBottom: "10px",
        marginTop: "-5px",
        marginBottom: "16px",
        borderBottom: "1px solid " + palette.background
      }}>{props.title}</h2>
      <div>
        {props.image ? <NamedImage style={{ marginBottom: "20px", maxWidth: props.imageMaxWidth ?? "100%", mixBlendMode: "normal" }} name={props.image} /> : null}
        <div>{props.text}</div>
      </div>
    </div>
  </div>
}

export default InfoBox;