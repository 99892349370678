import { ActionType, AnyAction } from "../actions/actions"
import { defaultLightsState, defaultPlayerGUIState as defaultGUIState, IAppState, IGUIState, LightId, TurnInfoStep } from "../store/app-state"
import { midiManager } from "../store/store"

export const mainReducer = (state: IAppState, action: AnyAction): IAppState => {
  switch (action.type) {
    case ActionType.setGameState:
      const prevTurnIndex = state.guiState.previousTurnIndex
      const turnIndex = action.state.turnIndex
      const guiState: IGUIState = { ...state.guiState, previousTurnIndex: turnIndex }
      // console.log({ prevTurnIndex, turnIndex })
      // console.log(guiState)
      if (prevTurnIndex != turnIndex || state.gameState == null) {
        // Moved to the next turn or started a new game
        guiState.currentInfoStep = action.state.isKiosk ? TurnInfoStep.lookAtBigScreen : TurnInfoStep.turnInfo
      }
      return {
        ...state,
        gameState: action.state,
        guiState
      }
    case ActionType.setPlayerState:
      return {
        ...state,
        playerState: action.state
      }
    case ActionType.clearGameState:
      return {
        playerState: null,
        gameState: null,
        guiState: defaultGUIState,
        lightsState: defaultLightsState
      }
    case ActionType.setQuitModalIsVisible:
      return {
        ...state,
        guiState: {
          ...state.guiState,
          quitModalIsVisible: action.isVisible
        }
      }
    case ActionType.setHeartBeatStatus:
      return {
        ...state,
        guiState: {
          ...state.guiState,
          heartbeatStatus: action.status,
          heartBeatResponseTimeMs: action.responseTimeMs
        }
      }
    case ActionType.setInfoStep: {
      return {
        ...state,
        guiState: {
          ...state.guiState,
          currentInfoStep: action.infoStep
        }
      }
    }
    case ActionType.setLightIntensity: {
      const lightsState = { ...state.lightsState }
      lightsState.lightIntensities[action.light] = action.intensity
      midiManager.setLightIntensity(action.light, action.intensity)
      return {
        ...state,
        lightsState
      }
    }
    case ActionType.setLightIntenities: {
      midiManager.setLightIntensity(LightId.First, action.intensities[0])
      midiManager.setLightIntensity(LightId.Second, action.intensities[1])
      midiManager.setLightIntensity(LightId.Third, action.intensities[2])
      midiManager.setLightIntensity(LightId.Fourth, action.intensities[3])
      return {
        ...state,
        lightsState: {
          lightIntensities: action.intensities
        }
      }
    }
  }
  return {
    ...state
  }
}