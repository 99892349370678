import { IGameState, IPlayerState } from "../api-types"

export const blankPlayerState: IPlayerState = {
  playerId: "",
  teamIndex: 0,
  latestHeartbeatAt: 0,
  hasRequestedNextIntroStep: false,
  hasRequestedNextTurnOutroStep: false,
  hasRequestedRetryTurn: false,
  isPresent: false
}

export enum HeartbeatStatus {
  ok = "ok",
  timeout = "timeout",
  error = "error"
}

export enum TurnInfoStep {
  turnInfo = "turnInfo", // non kiosk only
  lookAtBigScreen = "lookAtBigScreen", // kiosk only
  optionsInfo = "optionsInfo"
}

export interface IGUIState {
  currentInfoStep: TurnInfoStep | null
  quitModalIsVisible: boolean
  heartbeatStatus: HeartbeatStatus | null
  heartBeatResponseTimeMs: number | null
  previousTurnIndex: number | null
}

export const defaultPlayerGUIState: IGUIState = {
  currentInfoStep: null,
  quitModalIsVisible: false,
  heartbeatStatus: null,
  previousTurnIndex: null,
  heartBeatResponseTimeMs: null
}

export interface ILightsState {
  // Normalized intensities [0, 1]
  lightIntensities: [number, number, number, number]
}

export enum LightId {
  First = 0,
  Second = 1,
  Third = 2,
  Fourth = 3
}

export const defaultLightsState: ILightsState = {
  lightIntensities: [0, 0, 0, 0]
}

export interface IAppState {
  gameState: IGameState | null
  playerState: IPlayerState | null
  guiState: IGUIState,
  lightsState: ILightsState
}

export const defaultAppState: IAppState = {
  gameState: null,
  playerState: null,
  guiState: defaultPlayerGUIState,
  lightsState: defaultLightsState
}
