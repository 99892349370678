import { useState } from "react";
import { IGameState, IPlayerState } from "../../api-types";
import { appConfig, createJoinUrl } from "../../app-config";
import { GameStateUtil } from "../../store/game-state";
import { numberStrings } from "../../text-util";
import { palette, teamColors } from "../../theme";
import Button from "../Button";
import Spinner from "../Spinner";
const QRCode = require("qrcode.react");

const PlayerDot = (props: { isCurrentPlayer: boolean, playerIndex: number | null, teamIndex: number | null }) => {
  const bgColor = props.teamIndex != null ? palette.dimmedDark : palette.dimmedLight
  const textColor = props.teamIndex != null ? "white" : "black"
  const size = "25px"
  const radius = "15px"
  return <div>
    <div style={{marginBottom: "5px", lineHeight: size, color: palette.dimmedDark, backgroundColor: bgColor, width: size, height: size, borderRadius: radius}} >
       { props.playerIndex != null ? "" : "" }
    </div>
  </div>
}

const Lobby = (props: { gameState: IGameState, playerState: IPlayerState }) => {
  const joinUrl = createJoinUrl(props.gameState.id)
  const [copyUrlMessage, setCopyUrlMessage] = useState("")
  const urlCopyEnabled = navigator.clipboard != undefined

  const copyJoinUrl = () => {
    setCopyUrlMessage("")
    navigator.clipboard.writeText(joinUrl)
      .then(() => {
        setCopyUrlMessage("Länken har kopierats!")
      })
      .catch((error) => {
        setCopyUrlMessage(error)
      })
  }

  const renderDevInviteLink = () => {
    if (appConfig.isProduction) {
      return null
    }

    return <div className="dev" style={{ padding: "10px" }}>
      <a target="_blank" href={joinUrl}>Öppna länk i ny flik</a>
    </div>
  }

  const missingPlayerCountString = () => {
    const missingPlayerCount = GameStateUtil.missingPlayerCount(props.gameState)

    if (missingPlayerCount > numberStrings.length) {
      return missingPlayerCount.toString()
    }
    return numberStrings[missingPlayerCount - 1]
  }

  const renderInviteInfo = () => {
    return <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "30px" }}>
      <QRCode size={120} value={joinUrl} bgColor={palette.background} fgColor={palette.text} />
      <div style={{ width: "200px", color: palette.dimmedDark, marginTop: "10px" }}>Scanna QR-koden för att gå med i spelomgången</div>
      <div style={{position: "relative"}}>
        {urlCopyEnabled ? <Button style={{ marginTop: "30px" }} title="Kopiera inbjudningslänk" onClick={() => copyJoinUrl()} /> : <div style={{ paddingTop: "20px", fontSize: "14px" }} >Inbjudningslänk: {joinUrl}</div>}
        <div style={{ position: "absolute", textAlign: "center", width: "100%", paddingTop: "20px", paddingBottom: "20px", color: palette.dimmedDark }}>{copyUrlMessage}</div>
      </div>
  </div>
  }

  return <div style={{
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  }}>
    <h2 style={{ margin: 0 }} >Väntar på {missingPlayerCountString()} spelare</h2>
    <div style={{ marginTop: "10px", marginBottom: "10px" }} >
      <Spinner />
    </div>
    <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
      {props.gameState.playerStates.map((playerState, playerIndex) => {
        const teamIndex = GameStateUtil.playerTeamIndex(props.gameState, playerIndex)
        const isCurrentPlayer = playerState.playerId == props.playerState.playerId
        const isPresent = playerState.isPresent
        return <PlayerDot
          key={playerIndex}
          isCurrentPlayer={isCurrentPlayer}
          playerIndex={isPresent ? playerIndex : null}
          teamIndex={isPresent ? teamIndex : null}
        />
      })}
    </div>
    { renderInviteInfo() }

  </div>
}

export default Lobby;