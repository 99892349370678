import { IGameState } from "../../api-types"
import { teamColors } from "../../theme"

const TeamScores = (props: { gameState: IGameState }) => {
  return <div>
    <h3 style={{margin: "10px"}}>Total poängställning</h3>
    <div style={{ display: "flex", justifyContent: "center" }}>
      { props.gameState.teamScores.map((score, teamIndex) => {
        const rotations = [2, 1, -4]
        return <div key={teamIndex} style={{
          mixBlendMode: "multiply",
          transform: "rotate(" + rotations[teamIndex] + "deg)",
          fontWeight: "bold",
          fontSize: "30px",
          backgroundColor: teamColors[teamIndex],
          lineHeight: "60px",
          width: "60px",
          height: "60px",
          marginLeft: "-3px",
          marginRight: "-3px"
        }}>{score}</div>
      })}
    </div>
  </div>
}

export default TeamScores