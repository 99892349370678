import { IGameState, IPlayerState } from "../../api-types";
import { ITurnContent } from "../../content/game-content-types";
import { GameStateUtil } from "../../store/game-state";
import { palette, teamColors } from "../../theme";
import Silhouette from "../Silhouette";

const VotingOptions = (props: { gameState: IGameState, playerState: IPlayerState, turn: ITurnContent, onNext: () => void }) => {
  const playerIndex = GameStateUtil.playerIndex(props.gameState, props.playerState.playerId)
  const playerTeamIndex = GameStateUtil.playerTeamIndex(props.gameState, playerIndex)
  const playerOption = props.turn.votingOptions[playerTeamIndex]
  const otherOptions = props.turn.votingOptions
    .map((option, index) => {
      return {
        ...option, teamIndex: index
      }
    }).filter((_, index) => index != playerTeamIndex)

  return <div style={{padding: "20px"}}>
    <h3 style={{ color: palette.dimmedDark, margin: 0}}>Få Märit att välja:</h3>
    <div style={{position: "relative"}}>
      <Silhouette width={160} color={teamColors[playerTeamIndex]}/>
      <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <h2 style={{margin: 0}}>{ playerOption.title } </h2>
        <div style={{ marginTop: "5px", width: "280px"}}>{ playerOption.description }</div>
      </div>
    </div>
    <h3 style={{ color: palette.dimmedDark, margin: 0, paddingBottom: "5px", marginTop: "10px", marginBottom: "10px", borderBottom: "1px solid " + palette.dimmedLight}}>
      De andra rösterna säger:
    </h3>
    <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", marginBottom: "10px" }}>
      { otherOptions.map((otherOption, index) => {
        return <div key={index} style={{ flex: 1, position: "relative", marginBottom: "20px" }}>
          <div style={{display: "flex", textAlign: "left"}}>
            <Silhouette style={{marginRight: "10px", marginLeft: "0px"}} width={40} color={teamColors[otherOption.teamIndex]}/>
            <div style={{ flex: 1 }}>
              <h3 style={{margin: 0, marginBottom: "5px"}}>{ otherOption.title }</h3>
              <div> { otherOption.description } </div>
            </div>
          </div>
        </div>
      }) }
    </div>
    <button className="button" onClick={() => props.onNext()}>Fortsätt</button>
  </div>
}

export default VotingOptions;