import { ITurnContent } from "../../content/game-content-types";
import Button from "../Button";
import NamedImage from "./NamedImage";

const InfoStep = (props: { turn: ITurnContent, onNext: () => void }) => {
  return <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
    <NamedImage style={{maxWidth: "250px"}} name={props.turn.introImage} />
    <p style={{marginBottom: "50px", maxWidth: "280px"}}>{props.turn.introText}</p>
    <Button title="Fortsätt" onClick={() => props.onNext()} />
  </div>
}

export default InfoStep;