import { palette } from "../theme"
import Button from "./Button"

export interface IModalProps {
  title: string,
  message: string,
  confirmTitle?: string,
  dismissTitle?: string,
  onAction: (didConfirm: boolean) => void
}

const Modal = (props: IModalProps) => {
  return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", top: 0, left: 0, position: "absolute", width: "100vw", height: "100vh", backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
    <div style={{ margin: "40px", marginLeft: "auto", marginRight: "auto", padding: "40px", maxWidth: "220px", backgroundColor: palette.background, textAlign: "center", borderRadius: "10px" }}>
      <h2 style={{ margin: 0 }}>{props.title}</h2>
      <p>{props.message}</p>
      <div style={{width: "100%", display: "flex"}}>
        {props.dismissTitle ? <Button style={{ flex: 1, marginRight: "20px", width: "70px" }} title={props.dismissTitle} onClick={() => props.onAction(false)} /> : null}
        {props.confirmTitle ? <Button style={{ flex: 1, width: "70px"}} title={props.confirmTitle} onClick={() => props.onAction(true)} /> : null}
      </div>
    </div>
  </div>
}

export default Modal