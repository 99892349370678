interface IConfig {
  apiBaseUrl: string
  homeUrl: string
  heartBeatIntervalMs: number
  kioskPollIntervalMs: number
  kioskTimeoutIntervalMs: number
  kioskTimeoutDeltaMs: number
  heartBeatMaxResponseTimeMs: number
  isProduction: boolean
  showDevInfo: boolean
  wifiName: string,
  wifiPassword: string
}

export const isProductionBuild = process.env.NODE_ENV == "production"

const devHostname = "localhost"
// const devHostname = "192.168.10.211" // hemma
// const devHostname = "192.168.1.15" // kontan

const apiBaseUrl = () => {
  if (isProductionBuild) {
    return "https://parlam-api.herokuapp.com/api/v1"
  } else {
    return "http://" + devHostname + ":3000/api/v1"
  }
}

const homeUrl = () => {
  if (isProductionBuild) {
    return "https://parlamarit.mod2021.se" // "https://blmus.herokuapp.com"
  } else {
    return "http://" + devHostname + ":3001"
  }
}

export const appConfig: IConfig = {
  apiBaseUrl: apiBaseUrl(),
  homeUrl: homeUrl(),
  heartBeatIntervalMs: 2000,
  kioskPollIntervalMs: 1500,
  kioskTimeoutIntervalMs: 8900,
  kioskTimeoutDeltaMs: 100,
  heartBeatMaxResponseTimeMs: 700,
  isProduction: isProductionBuild,
  showDevInfo: false,
  wifiName: "BohuslansMuseum",
  wifiPassword: "museet2016"
}

export const createJoinUrl = (gameId: string) => {
  return appConfig.homeUrl + "/join/" + gameId
}