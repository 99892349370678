import { palette } from "./theme"

export const capitalizeFirstLetter = (text: string): string => {
  const firstLetter = text.substring(0, 1)
  const rest = text.substring(1, text.length)
  return firstLetter.toLocaleUpperCase() + rest
}

export const voiceNames = [
  "turkosa rösten",
  "gula rösten",
  "skära rösten"
]

export const numberStrings = [
  "en",
  "två",
  "tre",
  "fyra",
  "fem",
  "sex",
  "sju",
  "åtta",
  "nio",
]

export interface IVoiceSectionMetadata {
  color: string,
  colorName: string,
  keyword: string,
  description: string
}

export const voiceSectionMetadata: IVoiceSectionMetadata[] = [
  {
    color: palette.accent1,
    colorName: "Turkosa",
    keyword: "tradition",
    description: "“Hur kan jag göra min familj stolt?” Den gör som man alltid har gjort."
  },
  {
    color: palette.accent2,
    colorName: "Gula",
    keyword: "person",
    description: "“Hur kan jag få det bäst av alla?“ Den gör det som gynnar henne själv."
  },
  {
    color: palette.accent3,
    colorName: "Skära",
    keyword: "revolution",
    description: "“Hur kan jag förändra samhället?” Den vågar tänka i nya banor."
  }
]