import { IGameState } from "../../api-types";
import { GameStateUtil } from "../../store/game-state";
import { palette, teamColors } from "../../theme";

const TeamsArc = (props: { gameState: IGameState }) => {
  const segmentStyle = (playerCount: number, section: number, segment: number) => {
    if (playerCount != GameStateUtil.playerCount(props.gameState)) {
      return {
        display: "none"
      }
    }

    const playersPerTeam = GameStateUtil.playersPerTeam(props.gameState)

    let fill = teamColors[section]
    const dimmedFill = "#c0c0c0"

    if (GameStateUtil.allVotesHaveBeenCast(props.gameState)) {
      // All votes have been cast. Show color of winning section
      const hasWinningOption = GameStateUtil.maxRelVoteCount(props.gameState) > 0.5
      if (!hasWinningOption) {
        // No winning option. Dimmed color for all teams
        fill = dimmedFill
      } else if (section != GameStateUtil.leadingOptionIndex(props.gameState)) {
        // There is a winning option. Dimmed color for losing teams
        fill = dimmedFill
      }
    } else {
      // Voting in progress. Dim segments corresponding to players who have voted
      const playerIndex = section * playersPerTeam + segment
      // console.log({ playersPerTeam, playerIndex, playerCount })
      const playerId = props.gameState.playerStates[playerIndex].playerId
      if (GameStateUtil.playerHasVoted(props.gameState, playerId)) {
        fill = dimmedFill
      }
    }

    return {
      fill,
      stroke: "none"
    }
  }
  return <svg style={{ mixBlendMode: "multiply" }} preserveAspectRatio="xMidYMid" viewBox="-100 0 200 100" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="#292d2f">
      <g id="player-count-3">
        <g id="section-0">
          <g id="segment-0" style={segmentStyle(3, 0, 0)}>
            <path d="M 48 0 C 65.33333333333333 0 82.66666666666666 0 100 0 A 100 100 0 0 1 50.000000000000014 86.60254037844386 C 41.33333333333334 71.59143337951359 32.66666666666667 56.58032638058333 24.000000000000007 41.569219381653056 A 48 48 0 0 0 48 0" />
          </g>
        </g>
        <g id="section-1">
          <g id="segment-0" style={segmentStyle(3, 1, 0)}>
            <path d="M 24.000000000000007 41.569219381653056 C 32.66666666666668 56.58032638058332 41.33333333333334 71.59143337951359 50.000000000000014 86.60254037844386 A 100 100 0 0 1 -49.99999999999998 86.60254037844388 C -41.333333333333314 71.5914333795136 -32.66666666666666 56.58032638058333 -23.99999999999999 41.569219381653056 A 48 48 0 0 0 24.000000000000007 41.569219381653056" />
          </g>
        </g>
        <g id="section-2">
          <g id="segment-0" style={segmentStyle(3, 2, 0)}>
            <path d="M -23.99999999999999 41.569219381653056 C -32.66666666666665 56.58032638058333 -41.333333333333314 71.5914333795136 -49.99999999999998 86.60254037844388 A 100 100 0 0 1 -100 1.2246467991473532e-14 C -82.66666666666667 1.0123746872951453e-14 -65.33333333333334 8.001025754429374e-15 -48 5.878304635907295e-15 A 48 48 0 0 0 -23.99999999999999 41.569219381653056" />
          </g>
        </g>
      </g>
      <g id="player-count-6">
        <g id="section-0">
          <g id="segment-0" style={segmentStyle(6, 0, 0)}>
            <path d="M 48 0 C 56.516666666666666 0 65.03333333333333 0 73.55 0 A 73.55 73.55 0 0 1 36.775000000000006 63.69616844834545 C 32.51666666666667 56.320518759447985 28.25833333333334 48.944869070550524 24.000000000000007 41.569219381653056 A 48 48 0 0 0 48 0" />
          </g>
          <g id="segment-1" style={segmentStyle(6, 0, 1)}>
            <path d="M 74.45 0 C 82.96666666666667 0 91.48333333333333 0 100 0 A 100 100 0 0 1 50.000000000000014 86.60254037844386 C 45.74166666666668 79.2268906895464 41.48333333333334 71.85124100064893 37.22500000000001 64.47559131175146 A 74.45 74.45 0 0 0 74.45 0" />
          </g>
        </g>
        <g id="section-1">
          <g id="segment-0" style={segmentStyle(6, 1, 0)}>
            <path d="M 24.000000000000007 41.569219381653056 C 28.25833333333334 48.944869070550524 32.51666666666667 56.320518759447985 36.775000000000006 63.69616844834545 A 73.55 73.55 0 0 1 -36.774999999999984 63.69616844834547 C -32.51666666666665 56.320518759448 -28.25833333333332 48.944869070550524 -23.99999999999999 41.569219381653056 A 48 48 0 0 0 24.000000000000007 41.569219381653056" />
          </g>
          <g id="segment-1" style={segmentStyle(6, 1, 1)}>
            <path d="M 37.22500000000001 64.47559131175146 C 41.48333333333334 71.85124100064893 45.74166666666668 79.2268906895464 50.000000000000014 86.60254037844386 A 100 100 0 0 1 -49.99999999999998 86.60254037844388 C -45.741666666666646 79.22689068954641 -41.48333333333332 71.85124100064893 -37.22499999999999 64.47559131175146 A 74.45 74.45 0 0 0 37.22500000000001 64.47559131175146" />
          </g>
        </g>
        <g id="section-2">
          <g id="segment-0" style={segmentStyle(6, 2, 0)}>
            <path d="M -23.99999999999999 41.569219381653056 C -28.258333333333322 48.944869070550524 -32.51666666666665 56.320518759448 -36.774999999999984 63.69616844834547 A 73.55 73.55 0 0 1 -73.55 9.007277207728783e-15 C -65.03333333333333 7.964286350454953e-15 -56.516666666666666 6.921295493181125e-15 -48 5.878304635907295e-15 A 48 48 0 0 0 -23.99999999999999 41.569219381653056" />
          </g>
          <g id="segment-1" style={segmentStyle(6, 2, 1)}>
            <path d="M -37.22499999999999 64.47559131175146 C -41.48333333333332 71.85124100064893 -45.741666666666646 79.22689068954641 -49.99999999999998 86.60254037844388 A 100 100 0 0 1 -100 1.2246467991473532e-14 C -91.48333333333333 1.1203477134199704e-14 -82.96666666666667 1.0160486276925874e-14 -74.45 9.117495419652046e-15 A 74.45 74.45 0 0 0 -37.22499999999999 64.47559131175146" />
          </g>
        </g>
      </g>
      <g id="player-count-9">
        <g id="section-0">
          <g id="segment-0" style={segmentStyle(9, 0, 0)}>
            <path d="M 48 0 C 53.577777777777776 0 59.15555555555556 0 64.73333333333333 0 A 64.73333333333333 64.73333333333333 0 0 1 32.366666666666674 56.06071113831266 C 29.577777777777786 51.23021388609279 26.788888888888895 46.39971663387293 24.000000000000007 41.569219381653056 A 48 48 0 0 0 48 0" />
          </g>
          <g id="segment-1" style={segmentStyle(9, 0, 1)}>
            <path d="M 65.63333333333333 0 C 71.21111111111111 0 76.78888888888888 0 82.36666666666666 0 A 82.36666666666666 82.36666666666666 0 0 1 41.18333333333334 71.33162575837825 C 38.394444444444446 66.50112850615838 35.60555555555556 61.67063125393852 32.81666666666667 56.840134001718646 A 65.63333333333333 65.63333333333333 0 0 0 65.63333333333333 0" />
          </g>
          <g id="segment-2" style={segmentStyle(9, 0, 2)}>
            <path d="M 83.26666666666667 0 C 88.84444444444445 0 94.42222222222222 0 100 0 A 100 100 0 0 1 50.000000000000014 86.60254037844386 C 47.21111111111112 81.77204312622399 44.42222222222223 76.94154587400412 41.63333333333334 72.11104862178425 A 83.26666666666667 83.26666666666667 0 0 0 83.26666666666667 0" />
          </g>
        </g>
        <g id="section-1">
          <g id="segment-0" style={segmentStyle(9, 1, 0)}>
            <path d="M 24.000000000000007 41.569219381653056 C 26.788888888888895 46.39971663387293 29.577777777777786 51.23021388609279 32.366666666666674 56.06071113831266 A 64.73333333333333 64.73333333333333 0 0 1 -32.36666666666665 56.06071113831267 C -29.577777777777765 51.2302138860928 -26.788888888888877 46.39971663387293 -23.99999999999999 41.569219381653056 A 48 48 0 0 0 24.000000000000007 41.569219381653056" />
          </g>
          <g id="segment-1" style={segmentStyle(9, 1, 1)}>
            <path d="M 32.81666666666667 56.840134001718646 C 35.60555555555556 61.67063125393852 38.394444444444446 66.50112850615838 41.18333333333334 71.33162575837825 A 82.36666666666666 82.36666666666666 0 0 1 -41.18333333333331 71.33162575837827 C -38.394444444444424 66.5011285061584 -35.60555555555553 61.670631253938524 -32.81666666666665 56.84013400171865 A 65.63333333333333 65.63333333333333 0 0 0 32.81666666666667 56.840134001718646" />
          </g>
          <g id="segment-2" style={segmentStyle(9, 1, 2)}>
            <path d="M 41.63333333333334 72.11104862178425 C 44.42222222222223 76.94154587400412 47.21111111111112 81.77204312622399 50.000000000000014 86.60254037844386 A 100 100 0 0 1 -49.99999999999998 86.60254037844388 C -47.21111111111109 81.772043126224 -44.4222222222222 76.94154587400413 -41.63333333333331 72.11104862178426 A 83.26666666666667 83.26666666666667 0 0 0 41.63333333333334 72.11104862178425" />
          </g>
        </g>
        <g id="section-2">
          <g id="segment-0" style={segmentStyle(9, 2, 0)}>
            <path d="M -23.99999999999999 41.569219381653056 C -26.788888888888877 46.39971663387293 -29.577777777777765 51.2302138860928 -32.36666666666665 56.06071113831267 A 64.73333333333333 64.73333333333333 0 0 1 -64.73333333333333 7.927546946480534e-15 C -59.15555555555556 7.244466176289454e-15 -53.577777777777776 6.561385406098374e-15 -48 5.878304635907295e-15 A 48 48 0 0 0 -23.99999999999999 41.569219381653056" />
          </g>
          <g id="segment-1" style={segmentStyle(9, 2, 1)}>
            <path d="M -32.81666666666665 56.84013400171865 C -35.60555555555553 61.670631253938524 -38.394444444444424 66.5011285061584 -41.18333333333331 71.33162575837827 A 82.36666666666666 82.36666666666666 0 0 1 -82.36666666666666 1.0087007468977032e-14 C -76.78888888888888 9.403926698785952e-15 -71.21111111111111 8.720845928594874e-15 -65.63333333333333 8.037765158403793e-15 A 65.63333333333333 65.63333333333333 0 0 0 -32.81666666666665 56.84013400171865" />
          </g>
          <g id="segment-2" style={segmentStyle(9, 2, 2)}>
            <path d="M -41.63333333333331 72.11104862178426 C -44.4222222222222 76.94154587400413 -47.21111111111109 81.772043126224 -49.99999999999998 86.60254037844388 A 100 100 0 0 1 -100 1.2246467991473532e-14 C -94.42222222222222 1.1563387221282454e-14 -88.84444444444445 1.0880306451091373e-14 -83.26666666666667 1.0197225680900295e-14 A 83.26666666666667 83.26666666666667 0 0 0 -41.63333333333331 72.11104862178426" />
          </g>
        </g>
      </g>
      <g style={{ stroke: "white", strokeWidth: "1px" }}>
        <line id="separator-line-0" x1="0" x2="52.500000000000014" y1="0" y2="90.93266739736605" />
        <line id="separator-line-1" x1="0" x2="-52.49999999999998" y1="0" y2="90.93266739736606" />
      </g>
    </g>
  </svg>
}

export default TeamsArc;