import axios from "axios";
import { useContext, useReducer, useState } from "react";
import { setGameState, setInfoStep } from "../../actions/actions";
import { IGameState, IPlayerState } from "../../api-types";
import { gameContent } from "../../content/game-content";
import { ITurnContent, IVotingOption } from "../../content/game-content-types";
import { TurnInfoStep } from "../../store/app-state";
import { GameStateUtil } from "../../store/game-state";
import { store } from "../../store/store";
import { voiceNames } from "../../text-util";
import { palette, teamColors } from "../../theme";
import Button from "../Button";
import Silhouette from "../Silhouette";
import Spinner from "../Spinner";
import GameCompleted from "./GameCompleted";
import InfoBox from "./InfoBox";
import TurnIntro from "./InfoStep";
import Intro from "./Intro";
import IntroVoices from "./IntroVoices";
import IntroYourVoice from "./IntroYourVoice";
import KioskReadInstructions from "./KioskReadInstructions";
import ProceedButtonOrSpinner from "./ProceedButtonOrSpinner";
import RetryTurn from "./RetryTurn";
import TeamScores from "./TeamScores";
import VotingOptions from "./VotingOptions";

const TurnInfoBoxContent = (props: { gameState: IGameState, turn: ITurnContent, onProceed: () => void, hasProceeded: boolean }) => {
  const isKiosk = props.gameState.isKiosk
  return <div>
    { isKiosk ? <KioskReadInstructions title={props.turn.outroInfoTitle} /> :  <InfoBox title={props.turn.outroInfoTitle} text={props.turn.outroInfoText} image={props.turn.outroInfoImage} /> }
    <ProceedButtonOrSpinner onProceed={() => props.onProceed()} showSpinner={props.hasProceeded} />
  </div>
}

const ConsequenceContent = (props: { gameState: IGameState, turn: ITurnContent, playerState: IPlayerState, winningOption: IVotingOption, winningOptionIndex: number, hasProceeded: boolean, onProceed: () => void }) => {
  const isKiosk = props.gameState.isKiosk
  return <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
    { isKiosk ? null : <div style={{position: "relative", width: "100%"}}>
      <Silhouette width={80} style={{ }} color={teamColors[props.winningOptionIndex]}  />
      <div style={{ position: "absolute", left: "50%", top: "50%", width: "100%", transform: "translate(-50%, -50%)"}}>
        <h3 style={{ color: palette.dimmedDark, margin: "0px", marginTop: "10px"}}>Märit valde:</h3>
        <h3 style={{margin: 0}}>{ props.turn.votingOptions[props.winningOptionIndex].title }</h3>
      </div>
    </div> }

    { isKiosk ? null : <h2 style={{ margin: 0 }}>{ props.winningOption.consequenceTitle }</h2> }
    { isKiosk ? null : <div style={{maxWidth: "280px", marginTop: "6px", marginBottom: "6px"}}>
      {props.winningOption.consequenceText}
    </div> }
    { isKiosk ? <KioskReadInstructions title={props.winningOption.consequenceTitle} /> : null }
    { isKiosk ? null : <div style={{ marginBottom: "16px" }}><TeamScores gameState={props.gameState} /></div> }
    <ProceedButtonOrSpinner onProceed={() => props.onProceed()} showSpinner={props.hasProceeded} />
  </div>
}

const Turn = (props: { gameState: IGameState, playerState: IPlayerState }) => {
  const { state, dispatch } = useContext(store);
  const gameState = props.gameState
  const playerState = props.playerState
  const playerIndex = GameStateUtil.playerIndex(props.gameState, playerState.playerId)
  const turn = gameContent.turns[props.gameState.turnIndex]
  const playerHasVoted = GameStateUtil.playerHasVoted(gameState, playerState.playerId)
  const playerVote = gameState.playerVotes[playerIndex]
  const waitingForVotes = !GameStateUtil.allVotesHaveBeenCast(gameState)
  const infoStep = state.guiState.currentInfoStep

  // Game completed?
  if (gameState.turnIndex == gameState.totalTurnCount) {
    return <GameCompleted />
  }

  const requestNextIntroStep = () => {
    axios.put("/games/" + gameState.id + "/finish_intro_step", { playerId: playerState.playerId })
      .then((response) => {
        console.log(response)
        dispatch(setGameState(response.data))
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {

      })
  }

  const requestNextOutroStep = () => {
    axios.put("/games/" + gameState.id + "/finish_outro_step", { playerId: playerState.playerId })
    .then((response) => {
      console.log(response)
      dispatch(setGameState(response.data))
    })
    .catch((e) => {
      console.log(e)
    })
    .finally(() => {

    })
  }

  const registerVote = (teamIndex: number) => {
    axios.put(
      "/games/" + props.gameState.id + "/vote",
      { playerId: props.playerState.playerId, voteTeamIndex: teamIndex }
    ).then((response) => {
      console.log("Vote registered")
      dispatch(setGameState(response.data))
    }).catch((error) => {
      console.log("Failed to cast vote ")
      console.log(error)
    })
  }

  // Intro step with full screen layout?
  if (gameState.introStepIndex != null) {
    const hasRequestedNextStep = GameStateUtil.playerHasRequestedNextIntroStep(gameState, playerState.playerId)
    switch (gameState.introStepIndex) {
      case 0: {
        return <Intro isKiosk={gameState.isKiosk} hasProceeded={hasRequestedNextStep} onProceed={() => {
          requestNextIntroStep()
        }} />
      }
      case 1: {
        return <IntroVoices isKiosk={gameState.isKiosk} hasProceeded={hasRequestedNextStep} onProceed={() => {
          requestNextIntroStep()
        }} />
      }
      case 2: {
        return <IntroYourVoice isKiosk={gameState.isKiosk} hasProceeded={hasRequestedNextStep} teamIndex={playerState.teamIndex} onProceed={() => {
          requestNextIntroStep()
        }} />
      }
    }
  }

  // Voting complete with no winner?
  if (GameStateUtil.allVotesHaveBeenCast(gameState) && GameStateUtil.maxRelVoteCount(gameState) <= 0.5) {
    return <RetryTurn gameState={gameState} playerState={playerState} onProceed={() => {
      axios.put("/games/" + gameState.id + "/retry", { playerId: playerState.playerId })
        .then((response) => {
          dispatch(setGameState(response.data))
        })
        .catch((error) => {
          console.log("Retry error")
          console.log(error)
        })
    }} />
  }

  const renderCenterContent = () => {
    if (infoStep != null) {
      switch (infoStep) {
        case TurnInfoStep.turnInfo: {
          return <TurnIntro turn={turn} onNext={() => {
            dispatch(setInfoStep(TurnInfoStep.optionsInfo))
          }} />
        }
        case TurnInfoStep.optionsInfo: {
          return <VotingOptions gameState={gameState} playerState={playerState} turn={turn} onNext={() => {
            dispatch(setInfoStep(null))
          }} />
        }
        case TurnInfoStep.lookAtBigScreen: {
          return <div>
            <KioskReadInstructions title={ turn.title } />
            <Button title="Fortsätt" onClick={() => dispatch(setInfoStep(TurnInfoStep.optionsInfo)) } />
          </div>
        }
      }
    } else if (GameStateUtil.allVotesHaveBeenCast(gameState) && GameStateUtil.maxRelVoteCount(gameState) > 0.5) {
      const optionIndex = GameStateUtil.leadingOptionIndex(gameState)
      const option = turn.votingOptions[optionIndex]
      const hasRequestedNextStep = GameStateUtil.playerHasRequestedNextOutroStep(gameState, playerState.playerId)
      // Turn completed. Render outro step
      switch (gameState.turnOutroStepIndex) {
        case 0:
          return <ConsequenceContent
            turn={turn}
            playerState={playerState}
            gameState={gameState}
            winningOptionIndex={optionIndex}
            winningOption={option}
            onProceed={() => requestNextOutroStep() }
            hasProceeded={hasRequestedNextStep} />
        case 1:
          return <TurnInfoBoxContent gameState={gameState} turn={turn} onProceed={() => requestNextOutroStep()} hasProceeded={hasRequestedNextStep} />
      }
    } else if (waitingForVotes) {
      // Voting

      const renderBackLink = () => {
        return <a style={{ textDecoration: "underline", color: palette.dimmedDark }} onClick={() => dispatch(setInfoStep(TurnInfoStep.optionsInfo))} >Jag har glömt vad jag tycker</a>
      }

      const renderSpinner = () => {
        return <div>
          <div style={{ opacity: palette.dimmedAlpha }}>Inväntar övriga spelare</div>
          <Spinner />
        </div>
      }

      return <div>
        <h2 style={{ margin: 0, marginBottom: "10px" }}>Vad väljer du?</h2>

        <div style={{display: "flex", flexDirection: "column", padding: "10px", maxWidth: "350px"}}>
          {[0, 1, 2].map((teamIndex) => {
            const isDimmed = playerHasVoted && playerVote.teamIndex != teamIndex
            return <Button
            key={teamIndex}
            style={{
              backgroundColor: isDimmed ? palette.dimmedLight : teamColors[teamIndex],
              marginBottom: "20px",
              color: palette.text,
              fontSize: "24px"
            }}
            title={turn.votingOptions[teamIndex].title}
            onClick={() => {
              if (!playerHasVoted) {
                registerVote(teamIndex)
              }
            }}
          />
          })}
        </div>
        { gameState.isKiosk ? null : <div style={{marginBottom: "20px"}}><TeamScores  gameState={gameState} /></div> }
        <div style={{height: "50px"}}>
          { playerHasVoted ? renderSpinner() : renderBackLink() }
        </div>
      </div>

    } else {
      return null
    }
  }

  // If we made it here, the content should be rendered using
  // the standard layout with the title at the top etc
  return <div className="content-column" style={{ flex: 1, display: "flex", flexDirection: "column", width: "100%" }}>
    <div id="turn-top-content" style={{  }}>
      <div style={{ display: "flex", marginTop: "20px", justifyContent: "center"}} >
      { [...Array.from(Array(gameState.totalTurnCount).keys())].map((turnIdx) => {
        return <div key={turnIdx} style={{
          marginLeft: "3px",
          marginRight: "3px",
          width: "10px",
          height: "10px",
          borderRadius: "5px",
          backgroundColor: turnIdx <= gameState.turnIndex ? palette.dimmedDark : palette.dimmedLight
        }} ></div>
      }) }
      </div>
      <h3 style={{ textTransform: "uppercase", margin: 0, marginTop: "11px" }}>{ turn.year } – Vägval no. {gameState.turnIndex + 1}/{ gameState.totalTurnCount }</h3>
      <h1 style={{ margin: 0, paddingBottom: "5px", borderBottom: "1px solid " + palette.text }}>{turn.title}</h1>
    </div>
    <div style={{ flex: 1 }}></div>
    <div id="turn-center-content" style={{width: "100%"}} >
      <div style={{paddingLeft: "10px", paddingRight: "10px"}}>
      {renderCenterContent()}
      </div>
    </div>
    <div style={{ flex: 1 }}></div>
    <div id="turn-bottom-content">

    </div>
  </div>
}

export default Turn;