// Imported from Speltexter Parlamärit - Vägval.csv. DO NOT EDIT.

import { IGameContent } from './game-content-types';

export const importedGameContent: IGameContent = {
  turns: [
    {
      title: 'Märit väljer skor',
      year: 1892,
      introImage: 'shoe',
      introText: 'Märit är 10 år. Det är första vårdagen – en regnig lördag. Hon ska handla grönsaker med mamma på Kungstorget. Märit vill alltid välja själv – men vilka skor ska hon ta?',
      outroInfoTitle: 'Barfotabarn',
      outroInfoImage: 'barfotabarn',
      outroInfoText: 'Skor var dyra, ibland kunde de kosta flera månadslöner. Därför ärvdes skor ofta ner genom stora syskonskaror. När det blev vår började barn gå barfota för att spara på skornas sulor.',
      votingOptions: [
        {
          title: 'Kängor',
          image: '',
          description: 'Vinterskor blir bäst. De skyddar mot kyla och regn.',
          consequenceTitle: 'Karamellpromenad',
          consequenceImage: 'test',
          consequenceText: 'Märit snör på sig sina kängor. På Kungstorget lägger hon sin veckopeng på lördagsgodis hos en ung karamellförsäljare. Han heter August.'
        },
        {
          title: 'Finskor',
          image: 'test',
          description: 'Man vet aldrig vem man stöter på. Jag får putsa skorna när vi kommit hem igen.',
          consequenceTitle: ' Fina skor - fin konfekt',
          consequenceImage: '',
          consequenceText: 'Märit väljer sina finaste skor. De blir leriga men hon får ändå komplimanger. Särskilt från en ung karamellförsäljare som heter August.'
        },
        {
          title: 'Inga! Barfota',
          image: '',
          description: 'Jag vill känna leran mellan tårna. Och så nöter jag inte på mina skor.',
          consequenceTitle: 'Atjoo!',
          consequenceImage: '',
          consequenceText: 'Märit bråkar med mamma – men eftersom det är den första vårdagen får hon gå barfota. Hon fryser om fötterna. Hon blir förkyld. Atjoo! På Kungstorget får hon en polkagris av en ung karamellförsäljare. Han heter August.'
        }
      ]
    },
    {
      title: 'Märit slutar skolan',
      year: 1897,
      introImage: 'abacus',
      introText: 'Märit är 15 år. Hon vinkar farväl till Uddevalla Flickskola. Hon är bäst i klassen på att rita och räkna, men har inga pengar att klara sig på.\n' +
        'Vad ska Märit göra nu?',
      outroInfoTitle: 'Många kvinnliga fotografer  ',
      outroInfoImage: 'fotografer',
      outroInfoText: 'När kameran kom på 1800-talet fanns det ingen föreställning om antingen kvinnor eller män kunde jobba som fotografer. I Bohuslän öppnade många kvinnor fotoateljéer. Att fotograferas blev populärt och att byta visitkortsporträtt var ett slags socialt nätverkande – som dagens Facebook eller Instagram. Bara i Uddevalla fanns närmare 30 kvinnliga fotografer mellan 1860 och 1930. ',
      votingOptions: [
        {
          title: 'Bilda familj',
          image: '',
          description: 'Jag kan bli hemmafru och hålla ordning på hushållskassan. Då kan min man fokusera på att dra in pengar till familjen. Kan det vara August i klassen över mig kanske?',
          consequenceTitle: 'Svartens Café',
          consequenceImage: '',
          consequenceText: 'Märit besöker ofta Svartens Café i Rådhuset på Kungstorget. Flera unga män bjuder henne på kaffe och kaka. Men August syns aldrig till.Han har redan tagit jobb på Tändsticksfabriken. Märit blir bästa vän med caféets servitris – Annie.'
        },
        {
          title: 'Arbeta',
          image: '',
          description: 'Jag har hört att fotografen Maria Lundbäck i Uddevalla tar emot lärlingar. Kanske kan jag bli hennes assistent och få jobba med fotografi?',
          consequenceTitle: 'Lärling som fotograf',
          consequenceImage: '',
          consequenceText: 'Märit berättar för fotograf Maria Lundbäck att hon kan rita och räkna. År 1897 är inget på en kamera automatiskt utan allt måste räknas ut. Ljus, avstånd, bländare, slutare. Hon får plats som lärling och en vän i atlején  – fotoassistent Annie.'
        },
        {
          title: 'Studera',
          image: '',
          description: 'Jag kan söka stipendium till Eva Rodhes lärarutbildning i Göteborg. Männen skrattar, men Eva kämpar för att kvinnor också ska få rösta. Tänk om jag får göra det en dag?',
          consequenceTitle: 'Eva Rodhes skola',
          consequenceImage: '',
          consequenceText: 'Märit får stipendiet och reser till Göteborg. Hon tycker mycket om studierna, men avskyr storstaden. Hon får en nära vän i samma klass - Annie. De manliga studenterna behandlas bättre än dem, så Märit och Annie går med i Göteborgs kvinnoklubb. De engagerar sig i kampen för kvinnors rättigheter.'
        }
      ]
    },
    {
      title: 'Märit blir myndig',
      year: 1903,
      introImage: 'man',
      introText: 'Märit fyller 21 år och blir då myndig. Hennes far var hennes förmyndare innan dess. August har friat till henne. Hon tycker om honom. Gifter de sig blir Märit inte myndig själv utan August blir hennes förmyndare. Vad ska Märit göra nu? ',
      outroInfoTitle: 'Kvinnoöverskottet ',
      outroInfoImage: 'sambokvinnor',
      outroInfoText: 'Vid 1800-talets mitt flyttar många män till Amerika och det uppstår ett kvinnoöverskott i Sverige. Det finns inte tillräckligt med män för att försörja kvinnorna. Regeringen ger då kvinnor större möjlighet att starta egna företag, äga sina pengar och försörja sig själva. Många kvinnor flyttar ihop med varandra för att dela på kostnaderna. ',
      votingOptions: [
        {
          title: 'Gifta sig',
          image: '',
          description: 'Mina föräldrar tycker att jag ska bilda familj. Varför inte med August? Han är snäll och har redan ett arbete på Tändsticksfabriken.',
          consequenceTitle: 'Bröllopsfest',
          consequenceImage: '',
          consequenceText: 'Märit och August gifter sig i Uddevalla kyrka. På festen bjuds det på fläsk och karameller. De dansar hela natten men på morgonen går August till fabriken och jobbar tolv timmar som vanligt. Först år 1919 införs åtta timmars arbetsdag – men fortfarande sex dagar i veckan. '
        },
        {
          title: 'Flytta ihop',
          image: '',
          description: 'Jag vill inte gifta mig med någon ännu. Kanske kan jag klara mig om jag bor ihop med en vän?',
          consequenceTitle: 'Märit och Annie',
          consequenceImage: '',
          consequenceText: 'Märit tackar nej till Augusts frieri. Hon flyttar ihop med Annie i en lägenhet på Norra Drottninggatan i Uddevalla. De delar på hyra, mat och kläder – och en känsla av självständighet.'
        },
        {
          title: 'Bo själv',
          image: '',
          description: 'Jag vill klara mig själv. Lever jag trångt och snålt kan det gå. Då kan jag spela hur mycket fiol som helst hemma.',
          consequenceTitle: 'Egen tid',
          consequenceImage: '',
          consequenceText: 'Märit tackar nej till Augusts frieri. Hon väljer självständigheten som kommer med att bli myndig. Äntligen kan hon bestämma över sina egna pengar och leva som hon vill.'
        }
      ]
    },
    {
      title: 'Märit behöver pengar',
      year: 1905,
      introImage: 'camera',
      introText: 'Det är dyrt att leva i Uddevalla. Staden växer så det knakar. Märit behöver nu ett arbete så hon kan tjäna sina egna pengar.Hon är fortfarande bra på att räkna och rita. Vad ska Märit göra nu?',
      outroInfoTitle: 'Kvinnoyrken',
      outroInfoImage: 'kvinnoarbete',
      outroInfoText: 'Vid 1800-talets mitt ändras svensk lag för kvinnors självständighet. De får utbilda sig mer, driva företag och får rätt till en egen ekonomi. Ja, så länge du var myndig och ogift.Industrialismens uppfinningar skapar även fler yrken för kvinnor. Men det är först 1989 som kvinnor kan jobba inom alla yrken. Då får de bli stridspiloter.',
      votingOptions: [
        {
          title: 'Arbetare',
          image: '',
          description: 'Jag kan arbeta som packare på Tändsticksfabriken i Uddevalla. Eld kommer alltid att behövas, så här kan jag arbeta länge.',
          consequenceTitle: 'Tändsticksfabriken',
          consequenceImage: '',
          consequenceText: 'Märit får arbete i tändsticksfabriken, med att räkna så att rätt antal tändstickor hamnar i varje ask. Hon får också en vän vid det löpande bandet - Selma. Selma är med i den nystartade fackföreningen för arbetarna på fabriken.\n'
        },
        {
          title: 'Fotograf',
          image: '',
          description: 'Min vän Annie vill att vi startar en fotoateljé tillsammans. Vi behöver ta ett stort lån, men på sikt kan vi tjäna tillbaka pengarna.',
          consequenceTitle: 'Fotoateljén',
          consequenceImage: '',
          consequenceText: 'Märit och Annie startar en egen fotoateljé på Södra Hamngatan i Uddevalla. Det blir succé. Hurra! De kan redan efter några månader anställa fler assistenter och fotografer. En storkund som älskar att avporträtteras är kvinnorättskämpen Selma.'
        },
        {
          title: 'Småskollärare',
          image: '',
          description: 'Jag står inte ut med orättvisorna jag ser i samhället. Jag måste göra något! Som småskollärare kan jag i alla fall påverka barn att tänka mer jämlikt.',
          consequenceTitle: 'Lancasterskolan',
          consequenceImage: '',
          consequenceText: 'Lancasterskolan i Uddevalla anställer Märit som småskollärare. Varje dag äter hon lunch på lärarnas matställe vid Margretegärdeparken. Under luncherna pratar de om kvinnlig rösträtt. Märit blir bästis med rösträttskämpen Selma.'
        }
      ]
    },
    {
      title: 'Märit är orolig',
      year: 1907,
      introImage: 'protest',
      introText: 'Det stormar i Sverige. Kvinnor protesterar mot supandet bland arbetare. Fackföreningar demonstrerar för rimliga arbetsvillkor. Krav på allmän rösträtt skanderas i hela landet. Vad ska Märit göra nu?',
      outroInfoTitle: 'Alkolholförbud',
      outroInfoImage: 'nykterhet',
      outroInfoText: ' I Sverige genomförs en folkomröstning om alkoholförbud år 1922, med ca 49% för ett förbud och 51% emot.  I Amerika förbjuds alkohol helt mellan 1920 och 1933. Även i Finland.',
      votingOptions: [
        {
          title: 'Stanna hemma',
          image: '',
          description: 'Varför ska man bråka i onödan? Allt fungerar ju bra ändå. Jag vill inte riskera att bli av med mitt arbete. ',
          consequenceTitle: 'Själv är bästa piga',
          consequenceImage: '',
          consequenceText: 'Märit arbetar hårt utan att bry sig om alla nya tankar. Hon har råd att betala hyra och mat – och är nöjd så.'
        },
        {
          title: 'Kvinnorörelsen',
          image: '',
          description: 'Jag vill ha mer makt över mitt eget liv. Genom kvinnorörelsen kan jag få större inflytande. ',
          consequenceTitle: 'Kvinnor och möten',
          consequenceImage: '',
          consequenceText: 'Märit går på rösträttsmöte på stadshotellet i Uddevalla. Hon äter gott och lyssnar på rösträttskämpen Friggas roliga stand-up om kvinnlig rösträtt. '
        },
        {
          title: 'Nykterhetsrörelsen',
          image: '',
          description: 'Jag ser hur August och andra män super upp sina familjers pengar. Kvinnor blir sexuellt utnyttjade och allt är akoholens fel! Vi måste förbjuda alkohol i samhället.',
          consequenceTitle: 'Vita bandet ',
          consequenceImage: '',
          consequenceText: 'Märit ansluter sig till nykterhetsföreningen Vita bandet. Med andra kvinnor ställer hon sig framför krogarnas dörrar. De tvingar männen att gå hem till sina familjer istället för att dricka. Polis inkallas för att få bort kvinnorna.Tumult utbryter och Märit hamnar i häktet.'
        }
      ]
    },
    {
      title: 'Märit är gravid',
      year: 1909,
      introImage: 'stroller',
      introText: 'Märit är 27 år och väntar barn. Hennes liv har förändrats sedan August dog i en ångbåtsolycka. Efter flera sorger och förälskelser är hon ensam och osäker på vem pappan är. Men ogifta föräldrars barn kallades för oäkta och bar på en stor skam. Vad ska Märit göra nu?',
      outroInfoTitle: 'Fri abort i Sverige – först 1975 ',
      outroInfoImage: 'abort',
      outroInfoText: 'En av kvinnorörelsens största frågor var utbildning kring ofrivillig graviditet. Så kallade "änglamakerskor" hjälpte unga kvinnor att göra abort. Kvinnorna som behandlades riskerade sina liv.',
      votingOptions: [
        {
          title: 'Behålla',
          image: '',
          description: 'Även om barnet kallas för “oäkting” för att det fötts utanför äktenskapet, kommer jag att ta hand om det. ',
          consequenceTitle: 'Mödrahemmet',
          consequenceImage: '',
          consequenceText: 'Märit föder en frisk flicka. Som ensamstående mor ges Märit plats på ett statligt mödrahem i sex månader. Som hjälp får hon också utbildning i barnavård och hemsysslor.Annie stöder och hjälper Märit med flickan.'
        },
        {
          title: 'Lämna bort',
          image: '',
          description: 'Jag föder barnet men lämnar sedan bort det. Antingen till ett barnhem eller ett fosterhem. Skammen blir för tung för mig att bära själv.',
          consequenceTitle: 'Barnhemmet',
          consequenceImage: '',
          consequenceText: 'Märit föder en frisk flicka. Hon lämnas till “Barnhem för flickor” på Asplundsgatan 7 i Uddevalla. Hon skriver på ett kontrakt att hon inte ska söka kontakt med sin dotter. Märit gråter ut hos Annie. Annie tröstar Märit och beundrar hennes mod.'
        },
        {
          title: 'Abort',
          image: '',
          description: 'Jag kan inte ta hand om barnet själv, så det är bättre att det inte föds alls.  ',
          consequenceTitle: 'Abort - många år bort',
          consequenceImage: '',
          consequenceText: 'Märit vill göra abort – men hon får inte. År 1909 är det olagligt. Det fanns “änglamakerskor” – kvinnor som gjorde aborter illegalt. Annie tar Märit till en barnmorska i ett rivningshus i hamnen. Märit ångrar sig. Hon vänder om och föder ändå – en frisk flicka. Hon kan inte försörja sig själv och dottern, så barnet lämnas till “Barnhem för flickor” på Asplundsgatan i Uddevalla.'
        }
      ]
    },
    {
      title: 'Märit är frustrerad',
      year: 1914,
      introImage: 'clock',
      introText: 'Ett förslag om kvinnlig rösträtt, röstas ner i riksdagen. Landsföreningen för kvinnlig politisk rösträtt genomför då en stor namninsamling. Kvinnor i hela landet engagerar sig. Vad ska Märit göra nu?',
      outroInfoTitle: 'Den stora Namninsamlingen',
      outroInfoImage: 'namninsamling',
      outroInfoText: '1913–14 gjörs en omfattande namninsamling för kvinnlig politisk rösträtt runt om i Sverige. Över 350 000 namn samlas in i 30 inbundna volymer som lämnas till Sveriges riksdag. Kanske skrev din släkting på? Alla namn finns samlade på Riksarkivets hemsida.',
      votingOptions: [
        {
          title: 'Skriver inte på',
          image: '',
          description: 'Varför ska kvinnor plötsligt få rösta? Männen har skött allting bra tycker jag.',
          consequenceTitle: 'Korv är bättre än rösträtt',
          consequenceImage: '',
          consequenceText: 'Det är en solig lördag. Märit och Annie går till marknaden på torget. Vid statyn står rösträttskvinnorna och håller tal. Deras vän Selma kommer fram med listan. Märit skriver inte under. Hon köper en korv istället.'
        },
        {
          title: 'Skriver på',
          image: '',
          description: 'Jag tror på kvinnlig politisk rösträtt. Jag vill påverka mitt eget  liv. Klart jag skriver på. ',
          consequenceTitle: 'Många bäckar små',
          consequenceImage: '',
          consequenceText: 'Det är en solig lördag. Märit och Annie går till marknaden på torget. Vid statyn träffar de Selma och andra rösträttsvänner. Märit skriver under och tar några listor med sig. Hon ser till att så många vänner som möjligt också skriver under.'
        },
        {
          title: 'Samlar namn själv',
          image: '',
          description: 'Jag skriver självklart på. Jag ska banne mig se till att alla i hela Bohuslän också skriver på!',
          consequenceTitle: 'Turné för rösträtt',
          consequenceImage: '',
          consequenceText: 'Märit har fått listor budade hem till sig. Hon tar på sig sin snyggaste klänning och finaste hatt - det är viktigt att se proper ut. Hon tar tåget ut mot kusten och påbörjar sin resa. Under fyra veckor får hon in över 1000 namn. Succé! Många andra gör liknande resor. Nu kan de skapa förändring på riktigt!'
        }
      ]
    },
    {
      title: 'Märit är hungrig',
      year: 1917,
      introImage: 'fruits',
      introText: 'Första världskriget rasar genom Europa. Det finns ingen mat. Människor svälter ihjäl även i Sverige. Märit köar i timmar för bröd utan att få något. Hon somnar och vaknar hungrig. Nästa dag vaktar poliser butikerna så ingen stjäl mat. Vad ska Märit göra nu?',
      outroInfoTitle: 'Brödupproret 1917',
      outroInfoImage: 'brodupproret',
      outroInfoText: 'I maj 1917 fick mammor till hungriga barn i Göteborg nog av regeringens matransoner. De trängde sig in i butiker och krävde att få köpa bröd utan kuponger. 40 000 demonstranter orsakade kravaller, butiker plundrades och polis och militär sattes in. Brödupproret pressade regeringen att servera gratis soppa i hela landet. ',
      votingOptions: [
        {
          title: 'Smita förbi',
          image: '',
          description: 'Jag fick ingen mat igår, men jag ser flera i kön som fick det. Jag kan tränga mig före, fast betala. Jag är ingen tjuv. Ännu.',
          consequenceTitle: 'Hungern är bästa kryddan',
          consequenceImage: '',
          consequenceText: 'Märit visslar, låtsas att det regnar och tränger sig före de arga och hungriga kvinnorna som står i kön före henne.Hon får tag i en bit bröd och två potatisar, betalar och går hem. Märit somnar mätt för första gången på en vecka.'
        },
        {
          title: 'Plundra butiker',
          image: '',
          description: 'Jag blir galen av den här hungern! Jag bryr mig inte längre, jag måste stjäla mat.',
          consequenceTitle: 'Mätt – till vilket pris?',
          consequenceImage: '',
          consequenceText: 'Märit ser hur arga och hungriga kvinnor välter en matvagn. Märit kastar sig fram, stoppar två limpor bröd under blusen och springer därifrån. Hon ser militär och polis attackera de hungriga plundrarna. Märit somnar mätt för första gången på en vecka.'
        },
        {
          title: 'Starta ett matuppror ',
          image: '',
          description: 'Alla jag känner är hungriga och arga. Tillsammans kan vi få stopp på svälten i landet. ',
          consequenceTitle: 'Brödupproret',
          consequenceImage: '',
          consequenceText: 'Märit samlar de svältande kvinnorna på torget. De ropar efter bröd och kräver att politikerna gör något. Polis och militär anländer. Hungerkravaller utbryter här och runt om i landet, den största, Brödupproret, sker i Göteborg. Tack vara de hungriga kvinnornas kamp, ser regeringen till att servera gratis soppa till alla som vill ha.'
        }
      ]
    },
    {
      title: 'Märit får rösta',
      year: 1921,
      introImage: 'question',
      introText: 'Det är september 1921 och det första riksdagsvalet där kvinnor får rösta. Märit har äntligen rätt att påverka sitt eget liv och samhället. Vad ska Märit göra nu? ',
      outroInfoTitle: 'Vilka röstade 1921?',
      outroInfoImage: 'rosta',
      outroInfoText: 'I september 1921 kunde både män och kvinnor äntligen rösta till Riksdagens båda kammare. Nästan hälften av alla kvinnor röstar i valet 1921 och 62 procent av männen. 1922 tar fem kvinnor plats som ledamöter, bredvid 375 män i riksdagen.',
      votingOptions: [
        {
          title: 'Jag röstar inte',
          image: '',
          description: 'Det finns inget parti som bryr sig om mig så jag struntar i vilka som vinner valet.',
          consequenceTitle: 'Vem bryr sig?',
          consequenceImage: '',
          consequenceText: 'Annie övertalar Märit att rösta, men hon kan inte bestämma sig. Hon känner inte igen sig själv hos något av partierna så hon väljer att rösta blankt. Hon är osäker på om hon kommer rösta i nästa val. Det känns inte som att politik är något för henne.'
        },
        {
          title: 'Jag röstar! ',
          image: '',
          description: 'Jag vill kunna påverka så att jag får så bra som möjligt själv.',
          consequenceTitle: ' En egen röst',
          consequenceImage: '',
          consequenceText: 'Märit, Annie och Selma går och röstar. Märit är glad över att äntligen få vara med att påverka sitt eget liv i riksdagsvalet. Efter valet firar de med fika hos Selma. I rummet sprids en glädje - äntligen värderas en kvinnas och en mans röst lika mycket. '
        },
        {
          title: 'Jag ställer upp i valet! ',
          image: '',
          description: 'Många kan känna igen sig i mina erfarenheter. Får jag stöd kan jag förändra mångas liv till det bättre.',
          consequenceTitle: 'Riksdagen hägrar',
          consequenceImage: '',
          consequenceText: 'Märit reser runt och håller tal om kvinnors och barns rättigheter. Hon har utbildat sig i att hålla tal och tagit inspiration av rösträttskämpen Frigga Carlbergs sätt att använda satir och humor. Många tycker att Märits idéer är bra. Märit ställer upp i riksdagsvalet.'
        }
      ]
    }
  ]
}