import { voiceNames } from "../../text-util";
import { teamColors } from "../../theme";
import Silhouette from "../Silhouette";
import ProceedButtonOrSpinner from "./ProceedButtonOrSpinner";

const IntroYourVoice = (props: { teamIndex: number, onProceed: () => void, hasProceeded: boolean, isKiosk: boolean }) => {
  const voiceName = voiceNames[props.teamIndex]
  const voiceColor = teamColors[props.teamIndex]
  return <div style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    marginLeft: "15px",
    marginRight: "15px"
  }}>
    <div style={{position: "relative"}}>
      <Silhouette width={250} color={voiceColor}></Silhouette>
      <div style={{ width: "100%", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}} >
        <h2 style={{ margin: 0 }}>Du är den<br></br>{voiceName}!</h2>
      </div>
    </div>
    <h3>Se till att Märit gör som du vill!</h3>
    <ProceedButtonOrSpinner onProceed={() => props.onProceed()} showSpinner={props.hasProceeded} />
  </div>
}

export default IntroYourVoice;