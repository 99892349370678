import { palette } from '../../theme'
import HandImage from './../../images/pointinghand.png'

const KioskReadInstructions = (props: { title: string }) => {
  return <div style={{ marginBottom: "30px", marginTop: "20px"}}>
    <div>
      <img style={{width: "70px", marginTop: "0px", opacity: 0.6}} src={HandImage} />
    </div>
    <h2 style={{margin: 0, marginTop: "10px"}}>{ props.title }</h2>
    <h3 style={{ marginTop: "6px", color: palette.dimmedDark }}>Titta på stora skärmen</h3>
  </div>
}

export default KioskReadInstructions