import { IGameState, IPlayerState } from "../api-types"
import { HeartbeatStatus, LightId, TurnInfoStep } from "../store/app-state"

export interface IAction {
  type: ActionType
}

export enum ActionType {
  setGameState,
  setPlayerState,
  setInfoStep,
  setQuitModalIsVisible,
  setHeartBeatStatus,
  clearGameState,
  setLightIntensity,
  setLightIntenities,
}

interface ISetGameState extends IAction {
  type: ActionType.setGameState
  state: IGameState
}
export const setGameState = (state: IGameState): ISetGameState => ({
  type: ActionType.setGameState,
  state
})


interface IClearGameState extends IAction {
  type: ActionType.clearGameState
}
export const clearGameState = (): IClearGameState => ({
  type: ActionType.clearGameState
})


interface ISetInfoStep extends IAction {
  type: ActionType.setInfoStep
  infoStep: TurnInfoStep | null
}
export const setInfoStep = (infoStep: TurnInfoStep | null): ISetInfoStep => ({
  type: ActionType.setInfoStep,
  infoStep
})


interface ISetQuitModalIsVisible extends IAction {
  type: ActionType.setQuitModalIsVisible
  isVisible: boolean
}
export const setQuitModalIsVisible = (isVisible: boolean): ISetQuitModalIsVisible => ({
  type: ActionType.setQuitModalIsVisible,
  isVisible
})


interface ISetHeartBeatStatus extends IAction {
  type: ActionType.setHeartBeatStatus
  status: HeartbeatStatus
  responseTimeMs: number | null
}
export const setHeartBeatStatus = (status: HeartbeatStatus, responseTimeMs: number | null = null): ISetHeartBeatStatus => ({
  type: ActionType.setHeartBeatStatus,
  status,
  responseTimeMs
})

interface ISetPlayerState extends IAction {
  type: ActionType.setPlayerState
  state: IPlayerState
}
export const setPlayerState = (state: IPlayerState): ISetPlayerState => ({
  type: ActionType.setPlayerState,
  state
})

interface ISetLightIntensity extends IAction {
  type: ActionType.setLightIntensity
  light: LightId
  intensity: number
}
export const setLightIntensity = (light: LightId, intensity: number): ISetLightIntensity => ({
  type: ActionType.setLightIntensity,
  light,
  intensity
})

interface ISetLightIntensities extends IAction {
  type: ActionType.setLightIntenities
  intensities: [number, number, number, number]
}
export const setLightIntensities = (intensities: [number, number, number, number]): ISetLightIntensities => ({
  type: ActionType.setLightIntenities,
  intensities
})

export type AnyAction = ISetGameState | IClearGameState | ISetPlayerState | ISetQuitModalIsVisible | ISetInfoStep | ISetHeartBeatStatus | ISetLightIntensity | ISetLightIntensities