import Spinner from "../Spinner"

const ConnectionWarning = (props: {}) => {
  return <div style={{ textAlign: "center" }}>
    <p>
      Din anslutning verkar seg.<br></br>Försöker ansluta igen...
    </p>
    <div>
      <Spinner></Spinner>
    </div>
  </div>
}

export default ConnectionWarning