import { useEffect } from "react"
import { palette, teamColors } from "../../theme"
import Silhouette from "../Silhouette"
import { DrumPadManager } from "./KioskRoot"
import { ReactComponent as GavelImage } from './../../images/gavel.svg';
import SplashHeads from './../../images/splash_heads.png'
import Logo900 from './../../images/logo_900.png'

const KioskIdle = (props: { drumPadManager: DrumPadManager, onStartGameCreation: () => void }) => {
  useEffect(() => {
    props.drumPadManager.onDrumPadHit = onDrumPadHit
    console.log("KioskIdle mounted")
    return () => {
      console.log("KioskIdle unmounted")
    }
  }, [])

  const onDrumPadHit = () => {
    props.onStartGameCreation()
  }

  return <div style={{
    display: "flex",
    flex: 1,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  }}>
    <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", transform: "translate(-80px, 0px)", mixBlendMode: "multiply" }}>
      <div style={{display: "flex", transform: "rotate(-2deg)"}}>
        <h2 style={{mixBlendMode: "multiply", margin: 0, color: "rgb(54 191 193)", transform: "translate(100px, 40px) rotate(-10deg)"}}>Tradition!</h2>
        <h2 style={{mixBlendMode: "multiply", margin: 0, color: teamColors[1], transform: "translate(0, -10px) rotate(3deg)" }}>Person!</h2>
        <h2 style={{mixBlendMode: "multiply", margin: 0, color: teamColors[2], transform: "translate(-70px, 40px)  rotate(8deg)"}}>Revolution!</h2>
      </div>

      <div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <img src={SplashHeads} style={{ transform: "rotate(-2deg)", mixBlendMode: "multiply", width: "400px" }} />
    </div>
    <img src={Logo900} style={{transform: "rotate(-2deg)"}} />
    <h3 style={{ margin: 0, marginTop: "20px", transform: "rotate(-2deg)"}}>Vem ska Märit bli? Det bestämmer ni!</h3>
    </div>
    <div className="info-box-lg" style={{
      width: "700px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      padding: "60px",
      paddingTop: "100px",
      paddingBottom: "100px",
      height: "670px",
      transform: "rotate(1.5deg) translate(-170px)",
    }}
    >
      <h2 style={{margin: 0, marginBottom: "40px", fontSize: "54px"}}>Ett mobilspel för <br></br>minst tre personer</h2>
      <div>
        Märit har tre inre röster som vill olika saker. Gör din röst hörd för att vinna kampen om Märits liv.
      </div>
      <div>
        <GavelImage style={{ marginTop: "40px", width: "320px", stroke: palette.text, fill: palette.kioskBackground}} />
      </div>
      <div style={{marginTop: "10px"}}>
        Slå för att spela!
      </div>
    </div>
  </div>
}

export default KioskIdle;