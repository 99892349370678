import { useEffect, useState } from "react"
import { IGameState } from "../../api-types"
import { appConfig, createJoinUrl } from "../../app-config"
import { gameContent } from "../../content/game-content"
import { GameStateUtil } from "../../store/game-state"
import { palette, teamColors } from "../../theme"
import NamedImage from "../game/NamedImage"
import Silhouette from "../Silhouette"
import { DrumPadManager } from "./KioskRoot"
import TeamsArc from "./TeamsArc"
import TimeoutIcon from "./TimeoutIcon"
import { ReactComponent as GavelImage } from './../../images/gavel.svg'
import GavelImageVertical from './../../images/gavel_vertical.png'
import { capitalizeFirstLetter, numberStrings, voiceNames } from "../../text-util"
import KioskIntroSteps from "./KioskIntroSteps"
import { ITurnContent } from "../../content/game-content-types"
import MustDecideImage from './../../images/mustdecide.png'
import Spinner from "../Spinner"
import HandImage from './../../images/pointinghand_left.png'
import HeartImage from './../../images/heart.png'
import FinalScoreSilhouettes from "../FinalScoreSilhouettes"



const QRCode = require("qrcode.react")
const smallGridRowHeight = "200px"
const textColumnWidth = "900px"
const columnPadding = "30px"
const contentColumnFlex = 5
const dashboardColumnFlex = 2
const topBorderStyle = "3px solid " + palette.dimmedLight

let cancelCountdownHandle: any = null
let cancelOnDrumPadHit = false

enum KioskContentColumnType {
  turnIntro = "turnIntro",
  noWinner = "noWinner",
  consequenceText = "consequenceText",
  infoBox = "infoBox",
  gameCompleted = "gameCompleted"
}

const currentContentColumnType = (gameState: IGameState): KioskContentColumnType => {
  if (GameStateUtil.gameIsCompleted(gameState)) {
    return KioskContentColumnType.gameCompleted
  }

  const hasWinningOption = GameStateUtil.maxRelVoteCount(gameState) > 0.5
  const votingCompleted = GameStateUtil.allVotesHaveBeenCast(gameState)
  if (votingCompleted) {
    if (!hasWinningOption) {
      return KioskContentColumnType.noWinner
    } else if (gameState.turnOutroStepIndex == 0) {
      return KioskContentColumnType.consequenceText
    } else {
      return KioskContentColumnType.infoBox
    }
  }
  return KioskContentColumnType.turnIntro
}

const KioskContentColumn = (props: { gameState: IGameState, turn: ITurnContent, contentType: KioskContentColumnType }) => {
  const renderTopContent = () => {
    switch (props.contentType) {
      case KioskContentColumnType.turnIntro:
      case KioskContentColumnType.noWinner:
      case KioskContentColumnType.consequenceText:
        return <div style={{
          height: smallGridRowHeight,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderBottom: topBorderStyle
        }}>
          <div style={{transform: "translate(0, 6px)" }}>
            <h3 style={{margin: 0, color: palette.text }}>Vägval no. { props.gameState.turnIndex + 1 } – { props.turn.year }</h3>
            <h2 style={{margin: 0, color: palette.text, }}>{ props.turn.title }</h2>
          </div>
        </div>
      case KioskContentColumnType.infoBox:
        return null
      case KioskContentColumnType.gameCompleted:
        return null
    }
  }

  const renderCenterContent = () => {
    switch (props.contentType) {
      case KioskContentColumnType.turnIntro: {
        return <div style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <NamedImage name={props.turn.introImage} style={{ maxWidth: "400px", marginBottom: "40px" }} />
          <div style={{ width: textColumnWidth}}>
            { props.turn.introText }
          </div>
        </div>
      }
      case KioskContentColumnType.noWinner: {
        return <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", justifyContent: "center" }}>
          <img style={{width: "400px", mixBlendMode: "multiply"}} src={MustDecideImage} />
          <h2 style={{ margin: 0, marginBottom: "6px", width: "800px" }}>Märit måste bestämma sig!</h2>
          <div style={{ width: "800px", marginBottom: "40px" }} >
            Ni har inte nått ett majoritetsbeslut. <br></br>Det behöver ni för att gå vidare.
          </div>
        </div>
      }
      case KioskContentColumnType.infoBox: {
        return <div style={{flex: 1, width: "100%", display: "flex", flexDirection: "column", alignItems: "center", position: "relative"}}>
          <div style={{ width: "80%" }}>
            <div className="info-box-lg" style={{
              marginTop: "60px",
              height: "550px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backgroundColor: palette.text,
              color: palette.kioskBackground,
              padding: "40px",
              paddingLeft: "80px",
              paddingRight: "80px",
              transform: "rotate(-1deg)"
            }}>
              <h2 style={{margin: 0, marginBottom: "6px"}} >{ props.turn.outroInfoTitle }</h2>
              <div>{ props.turn.outroInfoText }</div>
          </div>

          <div style={{
            width: "100%",
            transform: "translate(0, -50px) rotate(0deg)",
            display: "flex",
            justifyContent: "center"
            }}>
            <div style={{
              backgroundColor: "black",

              width: "min-content",
              boxShadow: "7px 7px #00000030",
              display: "flex",
              border: "15px solid #e0e0e0"
            }}>
              <NamedImage style={{
                mixBlendMode: "normal",
                maxHeight: "340px",
                opacity: 0.8,
              }} name={props.turn.outroInfoImage}></NamedImage>
            </div>
          </div>

          </div>
        </div>
      }
      case KioskContentColumnType.consequenceText: {
        const winningOptionIndex = GameStateUtil.leadingOptionIndex(props.gameState)
        const winningOption = props.turn.votingOptions[winningOptionIndex]
        return <div style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative"
        }}>
          <h2 style={{margin: 0, marginBottom: "6px"}} >{ winningOption.consequenceTitle }</h2>
          <div style={{width: textColumnWidth }}>{ winningOption.consequenceText }</div>
          <div style={{ position: "absolute"}}>
            <Silhouette width={340} style={{mixBlendMode: "multiply"}} color={ teamColors[winningOptionIndex] } />
          </div>
        </div>
      }
      case KioskContentColumnType.gameCompleted: {
        return <div style={{  }}>
          <FinalScoreSilhouettes maxWidth={400} gameState={props.gameState} />
          <h2 style={{ margin: "10px" }}>Vem blev Märit?</h2>
          <div>Märits inre röster har diskuterat mycket genom åren.<br></br>Tack för att ni hjälpt henne välja vem hon ska bli!</div>
          <img style={{ transform: "scale(-1, 1) rotate(-14deg)", left: 0, top: 200, mixBlendMode: "multiply", width: "400px", opacity: 0.4, position: "absolute" }} src={HeartImage} />
          <img style={{ transform: "rotate(-24deg)", right: 600, top: 100, mixBlendMode: "multiply", width: "400px", opacity: 0.4, position: "absolute" }} src={HeartImage} />
        </div>
      }
    }
    return null
  }

  const renderBottomContent = () => {
    switch (props.contentType) {
      case KioskContentColumnType.turnIntro:
      case KioskContentColumnType.consequenceText:
        const winningOptionIndex = GameStateUtil.leadingOptionIndex(props.gameState)
        const voteCounts = GameStateUtil.voteCountsPerTeam(props.gameState)
        const counts = voteCounts.map((count) => {
          return props.contentType == KioskContentColumnType.turnIntro ? "" : count.count.toString()
        })

        return <div style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: smallGridRowHeight,
          borderTop: topBorderStyle
        }}>
          {[...props.turn.votingOptions].reverse().map((option, optionIndex) => {
            const colorIndex = props.turn.votingOptions.length - 1 - optionIndex
            const isDimmed = props.contentType == KioskContentColumnType.consequenceText && colorIndex != winningOptionIndex
            return <div
              key={optionIndex}
              style={{
                width: "390px",
                position: "relative"
              }}
            >
              <h3 style={{ margin: 0, color: isDimmed ? palette.dimmedDark : palette.text }} >{option.title}</h3>
              <Silhouette
                width={85}
                style={{ mixBlendMode: "multiply", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}
                color={isDimmed ? palette.dimmedLight : teamColors[colorIndex]}
              />
              <h2 style={{ margin: 0, color: colorIndex == winningOptionIndex ? palette.text : palette.dimmedLight, position: "absolute", left: "50%", transform: "translate(-50%, 0)", top: "-153px" }}>{ counts[colorIndex] }</h2>
            </div>
          })}
        </div>
    }
    return null
  }

  return <div style={{
    flex: contentColumnFlex,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    paddingLeft: columnPadding,
    paddingRight: columnPadding
  }}>
    { renderTopContent() }
    { renderCenterContent() }
    { renderBottomContent() }
  </div>
}

const KioskGameInfo = (props: {
  gameState: IGameState,
  drumPadManager: DrumPadManager,
  onCancel: () => void
}) => {
  const [timeoutSecondsLeft, setTimeoutSecondsLeft] = useState(0)
  const [isShowingCancelModal, setIShowingCancelModal] = useState(false)

  useEffect(() => {
    console.log("KioskGameInfo mounted")
    props.drumPadManager.onDrumPadHit = onDrumPadHit
    return () => {
      console.log("KioskGameInfo unmounted")
      resetCancelTimeout()
    }
  }, [])

  useEffect(() => {
    if (timeoutSecondsLeft < 0 && isShowingCancelModal) {
      resetCancelTimeout()
      setIShowingCancelModal(false)
    }
    cancelOnDrumPadHit = isShowingCancelModal
  }, [timeoutSecondsLeft, isShowingCancelModal])

  const resetCancelTimeout = () => {
    clearInterval(cancelCountdownHandle)
    cancelCountdownHandle = null
  }

  const setCancelTimeout = () => {
    resetCancelTimeout()
    setTimeoutSecondsLeft(0.001 * appConfig.kioskTimeoutIntervalMs)
    cancelCountdownHandle = setInterval(() => {
      setTimeoutSecondsLeft((prev) => prev - 0.001 * appConfig.kioskTimeoutDeltaMs)
    }, appConfig.kioskTimeoutDeltaMs)
  }

  const onDrumPadHit = () => {
    if (cancelOnDrumPadHit) {
      props.onCancel()
    } else {
      setCancelTimeout()
      setIShowingCancelModal(!isShowingCancelModal)
    }
  }

  const gameState = props.gameState
  const turn = gameContent.turns[gameState.turnIndex]
  const joinUrl = createJoinUrl(gameState.id)

  const renderTurnDashboardColumn = () => {
    const scoreBoxWidth = "120px"
    const scoreBoxAngles = [-2, 2, 4]
    const gameCompleted = GameStateUtil.gameIsCompleted(gameState)
    const votingCompleted = GameStateUtil.allVotesHaveBeenCast(gameState)
    const leadingOptionIndex = GameStateUtil.leadingOptionIndex(gameState)
    const hasWinningOption = GameStateUtil.maxRelVoteCount(gameState) > 0.5
    let votingHeading = "Röstning pågår"
    if (gameCompleted) {
      votingHeading = "Tack för att ni spelade!"
    }
    else if (votingCompleted) {
      if (hasWinningOption) {
        votingHeading = capitalizeFirstLetter(voiceNames[leadingOptionIndex]) + " vann!"
      } else {
        votingHeading = "Bestäm er!"
      }
    } else if (!GameStateUtil.votingIsInProgress(gameState)) {
      votingHeading = "Väntar på röster"
    }

    return <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      justifyContent: "center",
      paddingRight: columnPadding
    }}>
      <div style={{ height: smallGridRowHeight, width: "100%", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", borderBottom: topBorderStyle }}>
        <h3 style={{ margin: 0, marginBottom: "32px", color: palette.dimmedDark }}>Vägval {Math.min(gameState.totalTurnCount, gameState.turnIndex + 1)} av {gameState.totalTurnCount}</h3>
        <div style={{ display: "flex", width: "100%", justifyContent: "center", marginBottom: "14px" }}>
          {[...Array(gameState.totalTurnCount)].map((_, index) => {
            const turnCompleted = index <= gameState.turnIndex
            const dotSize = 25
            const dotSpacing = 9

            return <div key={index} style={{
              marginLeft: dotSpacing + "px",
              marginRight: dotSpacing + "px",
              width: dotSize + "px",
              height: dotSize + "px",
              borderRadius: 0.5 * dotSize + "px",
              backgroundColor: turnCompleted ? palette.dimmedDark : palette.dimmedLight
            }}></div>
          })}
        </div>
      </div>

      <div style={{
        flex: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center"
      }}>
        <h3 style={{ color: palette.dimmedDark, margin: 0, marginBottom: "18px" }}>{votingHeading} </h3>
        <div style={{ width: "78%" }}>
          <TeamsArc gameState={gameState} />
        </div>


        <div style={{ width: "100%", textAlign: "center" }} >
        <h3 style={{ color: palette.dimmedDark,  marginBottom: "28px" }}>Total poängställing</h3>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {GameStateUtil.currentTeamScores(gameState).reverse().map((score, columnIndex) => {
            const teamIndex = gameState.teamScores.length - 1 - columnIndex
            return <div key={teamIndex} style={{
              mixBlendMode: "multiply",
              width: scoreBoxWidth,
              height: scoreBoxWidth,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "4rem",
              fontWeight: "bold",
              backgroundColor: teamColors[teamIndex],
              transform: "rotate(" + scoreBoxAngles[teamIndex] + "deg) scale(1.1)"
            }}>
              {score}
            </div>
          })}
          </div>
        </div>
      </div>

      <div style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        textAlign: "center",
        position: "relative",
        justifyContent: "center",
        borderTop: topBorderStyle,
        height: smallGridRowHeight,
        overflow: "hidden"
         }} >
        <h3 style={{flex: 1, textAlign: "right", color: palette.dimmedDark}} >
          Avsluta
        </h3>
        <div style={{width: "140px"}} ></div>
        <h3 style={{flex: 1,textAlign: "left", color: palette.dimmedDark}} >
          spelet
        </h3>
        <div style={{ mixBlendMode: "multiply", position: "absolute", top: "40px", left: "50%", transform: "translate(-50%, 0)" }}>
          <img style={{ width: "160px", opacity: 0.5 }} src={GavelImageVertical} />
        </div>
      </div>
    </div>
  }

  const renderQuitModal = () => {
    return <div style={{
      backgroundColor: palette.text,
      height: "100%",
      width: "100%",
      display: isShowingCancelModal ? "flex" : "none",
      flexDirection: "column",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      left: 0,
      top: 0,
      color: palette.kioskBackground,
      textAlign: "center"
    }}>
      <GavelImage style={{ width: "500px", stroke: palette.text, fill: palette.kioskBackground, paddingBottom: "0px" }} />
      <h2>Slå på plattan igen<br></br> för att avsluta</h2>
      <div style={{ position: "absolute", bottom: "5%" }}>
        <TimeoutIcon color={palette.kioskBackground} relTimeRemaining={timeoutSecondsLeft / (0.001 * appConfig.kioskTimeoutIntervalMs)} />

      </div>
    </div>
  }

   // Full screen layouts?
  if (isShowingCancelModal) {
    return renderQuitModal()
  }
  else if (!GameStateUtil.allPlayersArePresent(gameState)) {
    // Full screen "Waiting for player(s)"
    return <div style={{ textAlign: "center", flex: 1, display: "flex", justifyContent: "center" }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "82%" }}>
        <div style={{ flex: 1}}>
          <a href={joinUrl} target="_blank"><QRCode size={700} value={joinUrl} bgColor={palette.kioskBackground} fgColor={palette.text} /></a>
        </div>
        <div style={{flex: 1}}>
          <img style={{ opacity: 0.9 }} src={HandImage} />
          <h2 style={{ margin: 0 }}>Scanna för att spela</h2>
          <h3 style={{ margin: "10px", color: palette.dimmedDark }}>Väntar på  { numberStrings[GameStateUtil.missingPlayerCount(gameState) - 1]} spelare</h3>
          <div style={{ display: "flex", justifyContent: "center"}}>
            <div style={{ width: "300px", transform: "scale(2, 2)"}}>
              <Spinner />
            </div>
          </div>
          <div>
          <h3 style={{ margin: "10px", marginTop: "50px"}}>Dålig täckning? </h3>
          <span style={{ color: palette.dimmedDark }}>WiFi:</span> { appConfig.wifiName }
            <br></br>
            <span style={{ color: palette.dimmedDark }}>Lösenord:</span> { appConfig.wifiPassword }
          </div>
        </div>
      </div>
    </div>
  } else if (gameState.introStepIndex != null) {
    // Full screen intro step
    return <KioskIntroSteps introStepIndex={gameState.introStepIndex} />
  }

  // Default two column layout
  return <div style={{
    display: "flex",
    flex: 1,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  }}>
    <div style={{ display: "flex", width: "100%", flex: 1 }}>
      <KioskContentColumn
        gameState={gameState}
        turn={turn}
        contentType={currentContentColumnType(gameState)}
      />
      <div style={{ display: "flex", flex: dashboardColumnFlex }}>
        {renderTurnDashboardColumn()}
      </div>
    </div>
  </div>
}

export default KioskGameInfo;