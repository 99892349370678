import { IGameState } from "../api-types"
import { GameStateUtil } from "../store/game-state"
import { teamColors } from "../theme"
import Silhouette from "./Silhouette"

const FinalScoreSilhouettes = (props: { gameState: IGameState, maxWidth: number }) => {
  interface IScoreSilhouette {
    teamIndex: number,
    scale: number
  }
  const teamScores = GameStateUtil.currentTeamScores(props.gameState)

  const maxScore = Math.max(1, Math.max(...teamScores))

  const minScale = 0.2
  const scoreSilhouettes: IScoreSilhouette[] = teamScores.map((score, teamIndex) => {
      return {
        scale: minScale + (1 - minScale) * score / maxScore,
        teamIndex
      }
  }).sort((a, b) => b.scale - a.scale)

  const temp = scoreSilhouettes[1]
  scoreSilhouettes[1] = scoreSilhouettes[0]
  scoreSilhouettes[0] = temp

  return <div style={{ }}>
    { scoreSilhouettes.map((silhouette, posIdx) => {
      const width = Math.round(silhouette.scale * props.maxWidth)
      let dx = 0
      if (posIdx == 0) {
        dx = 0.6 * width
      } else if (posIdx == 2) {
        dx = -0.6 * width
      }
      return <Silhouette key={posIdx} width={width} style={{ transform: "translate(" + dx + "px, 0)", mixBlendMode: "multiply" }} color={teamColors[silhouette.teamIndex]} />
    })}
  </div>
}

export default FinalScoreSilhouettes;