import { createContext, useEffect, useReducer } from "react";
import { AnyAction } from "../actions/actions";
import { IGameState, IPlayerState } from "../api-types";
import { MidiManager } from "../midi-manager";
import { mainReducer } from "../reducers/main-reducer";
import { blankPlayerState, defaultAppState, IAppState } from "./app-state";
import { defaultGameState as blankGameState } from "./game-state";

export interface IAppContext {
  state: IAppState
  dispatch: React.Dispatch<AnyAction>
}

export const midiManager = new MidiManager()
export const GameStateContext = createContext<{ gameState: IGameState, playerState: IPlayerState }>({ gameState: blankGameState, playerState: blankPlayerState})

export const store = createContext<IAppContext>({
  state: defaultAppState,
  dispatch: () => null
})

const { Provider } = store;

export const AppProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(mainReducer, defaultAppState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

