import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { clearGameState } from "../../actions/actions";
import { store } from "../../store/store";
import FinalScoreSilhouettes from "../FinalScoreSilhouettes";
import KioskReadInstructions from "./KioskReadInstructions";
import TeamScores from "./TeamScores";

const GameCompleted = (props: {}) => {
  const { state, dispatch } = useContext(store);
  const gameState = state.gameState
  const navigate = useNavigate()

  if (gameState == null) {
    return null
  }

  const renderKioskContent = () => {
    return <KioskReadInstructions title="Vem blev Märit?" ></KioskReadInstructions>
  }

  const renderNonKioskContent = () => {
    return <div>
      <FinalScoreSilhouettes maxWidth={160} gameState={gameState} />
      <h2 style={{margin: 0, marginBottom: "4px", marginTop: "10px"}} >Vem blev Märit?</h2>
      <div style={{ maxWidth: "250px" }}>Märits inre röster har diskuterat mycket genom åren.<br></br>Tack för att ni hjälpt henne välja vem hon ska bli!</div>
      <TeamScores gameState={gameState} />
    </div>
  }

  const isKiosk = gameState.isKiosk

  return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
    { isKiosk ? renderKioskContent() : renderNonKioskContent() }

    <button className="button" style={{marginTop: "20px"}} onClick={(e) =>{
        dispatch(clearGameState())
        navigate("/")
    }} >Avsluta</button>
  </div>
}

export default GameCompleted