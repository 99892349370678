import { ILightsState, LightId } from "./store/app-state";

export interface IMidiManagerProps {
  onDevicesChanged: () => void,
  onMidiAccessDenied: (error: any) => void
}

export class MidiManager {
  onDrumPadHit: (() => void) | null = null
  private props: IMidiManagerProps
  private outputs: WebMidi.MIDIOutput[] = []
  private inputs: WebMidi.MIDIInput[] = []
  private latestHitAt = 0
  private hasStarted = false
  private onMidiEvent: (e: any) => void;
  constructor() {
    this.onMidiEvent = (e) => {
      const data = e.data
      if (data.length == 3) {
        if (data[0] == 0x99) { // Note on on channel 10
          if (data[2] > 0) {
            // Non zero velocity. Interpret this as a hit.
            const now = Date.now()
            const timeSinceLatestHit = now - this.latestHitAt
            this.latestHitAt = now
            if (timeSinceLatestHit > 300) {
              // console.log("Hit! " + [data[0], data[1], data[2]])
              if (this.onDrumPadHit) {
                this.onDrumPadHit()
              }
            }
          }
        }
      }
    }
    this.props = { onDevicesChanged: () => {}, onMidiAccessDenied: (e) => {} }
  }

  startIfNotStarted(props: IMidiManagerProps) {
    if (this.hasStarted) {
      return;
    }
    this.hasStarted = true;
    this.log("Starting")
    this.props = { ...props }
    if (navigator.requestMIDIAccess) {
      navigator.requestMIDIAccess()
        .then((midi) => {
          this.log("Started!")
          midi.addEventListener(
            'statechange',
            (event) => {
              this.refresh(event.target as WebMidi.MIDIAccess)
            }
          );
          this.refresh(midi)
        }, (error) => {
          this.log("Failed to start")
          this.props.onMidiAccessDenied(error)
        });
    } else {
      this.log("requestMIDIAccess is undefined")
      this.props.onMidiAccessDenied("requestMIDIAccess is undefined")
    }
  }

  setLightIntensities(ligtsState: ILightsState) {
    for (const light of [LightId.First, LightId.Second, LightId.Third, LightId.Fourth]) {
      this.setLightIntensity(light, ligtsState.lightIntensities[light])
    }
  }

  setLightIntensity(light: LightId, intensity: number) {
    // Sending on MIDI channel 1.
    // Dimmer channels 1-4 correspond to note numbers 0-3
    const payload = [
      144,  // note on, channel 1
      light, // note number
      Math.round(Math.min(Math.max(0, intensity), 1) * 127) // velocity interpreted as light intensity
    ]
    for (const output of this.outputs) {
      this.log("Sending intensity " + intensity + " of light " + light + " to " + output.name + ", payload " + payload)
      output.send(payload)
    }
  }

  private log(message: string) {
    // console.log("MidiManager: " + message)
  }

  private refresh(midi: WebMidi.MIDIAccess) {
    this.outputs = []
    const outputs = midi.outputs.values();
    for (let output = outputs.next(); output && !output.done; output = outputs.next()) {
      this.outputs.push(output.value);
    }

    for (const input of this.inputs) {
      input.removeEventListener("midimessage", this.onMidiEvent)
    }

    this.inputs = []
    const inputs = midi.inputs.values();
    for (let input = inputs.next(); input && !input.done; input = inputs.next()) {
      input.value.addEventListener("midimessage", this.onMidiEvent)
      this.inputs.push(input.value);
    }

    this.log("Refreshed outputs: " + this.outputs.map(o => o.name))

    this.props.onDevicesChanged()
  }
}
