import { palette } from "../../theme";
import Silhouette from "../Silhouette";
import KioskReadInstructions from "./KioskReadInstructions";
import ProceedButtonOrSpinner from "./ProceedButtonOrSpinner";

const Intro = (props: { onProceed: () => void, hasProceeded: boolean, isKiosk: boolean }) => {
  const renderNonKioskText = () => {
    return <div style={{paddingLeft: "20px", paddingRight: "20px"}} >
      <h3 style={{ paddingBottom: "10px", margin: 0, borderBottom: "1px solid " + palette.text }}>Vem ska Märit bli? Det bestämmer ni!</h3>
      <p>
        Märit föds år 1878 och växer upp här i Uddevalla.
        Hennes föräldrar jobbar på tändssticksfabriken.
        Hon kommer att få se hela världen förändras,
        och hennes liv kommer att vara fullt av möjligheter.
      </p>
      <p>
        <strong>Men hon har tre inre röster som vill gå olika vägar.</strong>
      </p>
      <p>
        <strong>Ni är de tre rösterna.</strong>
      </p>
    </div>
  }

  const title = "Vem ska Märit bli?"
  const isKiosk = props.isKiosk

  return <div className="content-column" >
    { isKiosk ? null :  <div style={{ transform: "scale(0.6)", marginBottom: "-40px" }} >
      <Silhouette color={palette.accent1} style={{ mixBlendMode: "multiply", marginRight: "-40px" }} />
      <Silhouette color={palette.accent2} style={{ mixBlendMode: "multiply" }} />
      <Silhouette color={palette.accent3} style={{ mixBlendMode: "multiply", marginLeft: "-40px" }} />
    </div> }
    { isKiosk ? null : <h1 style={{ marginBottom: "5px" }}>{ title }</h1> }
    { isKiosk ? <KioskReadInstructions title={title} /> : renderNonKioskText() }
    <ProceedButtonOrSpinner onProceed={() => props.onProceed()} showSpinner={props.hasProceeded} />
  </div>
}

export default Intro;