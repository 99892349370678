
import axios from "axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useNavigate } from "react-router-dom";
import { palette } from "../theme";
import Button from "./Button";
import Spinner from "./Spinner";

const Join = (props: { }) => {
  const params = useParams()
  const gameId = params.gameId
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string | null>()

  useEffect(() => {
    // Mounted
    axios.put("/games/" + gameId + "/join")
      .then((response) => {
        navigate("/game", {  state: response.data})
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
    return () => {
      // unmounted
    }
  }, [])

  const showError = errorMessage != null;

  return <div style={{
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }}>
    <div style={{display: showError ? "none" : "flex", flexDirection: "column", alignItems: "center"}}>
      <h3 style={{ color: palette.dimmedDark}}>Ansluter till spelomgång</h3>
      <Spinner />
    </div>
    <div style={{display: showError ? "flex" : "none", flexDirection: "column", alignItems: "center"}}>
      <h1>Kunde inte ansluta</h1>
      <div>Kontrollera att det finns lediga platser och försök igen.</div>
      <p style={{ opacity: palette.dimmedAlpha}}>{errorMessage}</p>
      <a href="/">Till startsidan</a>
    </div>
  </div>
}

export default Join;