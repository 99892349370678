import { IVoiceSectionMetadata, voiceSectionMetadata } from "../../text-util"
import { palette } from "../../theme"
import Silhouette from "../Silhouette"
import KioskReadInstructions from "./KioskReadInstructions"
import ProceedButtonOrSpinner from "./ProceedButtonOrSpinner"

const IntroVoices = (props: { onProceed: () => void, hasProceeded: boolean, isKiosk: boolean }) => {
  const renderSection = (metadata: IVoiceSectionMetadata, key: number) => {
    return <div key={key} style={{ display: "flex", textAlign: "left", alignItems: "center",
      marginBottom: "20px" }}>
      <div style={{marginRight: "10px"}}>
        <Silhouette width={50} color={metadata.color} />
      </div>
      <div>
        <h2 style={{ margin: 0, marginBottom: "1px" }}>{metadata.colorName} rösten</h2>
        <div>
          Den {metadata.colorName.toLowerCase()} rösten säger <span style={{ padding: "2px", paddingLeft: "4px", paddingRight: "4px", background: metadata.color }}><strong>{metadata.keyword}</strong></span>. {metadata.description}
        </div>
      </div>
    </div>
  }

  const renderNonKioskContent = () => {
    return <div style={{ marginLeft: "20px", marginRight: "20px"}}>{voiceSectionMetadata.map((meta, key) => renderSection(meta, key))}</div>
  }

  return <div className="content-column">
    { props.isKiosk ? <KioskReadInstructions title="Märits tre röster" /> : renderNonKioskContent() }
    <ProceedButtonOrSpinner onProceed={() => props.onProceed() } showSpinner={props.hasProceeded} />
  </div>
}

export default IntroVoices;