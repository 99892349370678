const Silhouette = (props: { color: string, width?: number, style?: any }) => {
  const width = props.width ?? 100
  const style = props.style ?? {}
  return <div style={{ ...style, width, display: "inline-block" }}>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 216.89 305.28" >
      <path fill={props.color} d="M 20.55,114.29 C 24.36,91.25 42.08,64.54 66.66,56.22 C 66.63,55.53 66.49,54.84 66.56,54.15 C 68.87,32.09 78.52,11.12 104.58,11.59 C 130.48,12.06 140.35,26.53 144.58,54.17 C 144.68,54.85 144.52,55.54 144.49,56.22 C 169.06,64.54 187.2,91.25 191.01,114.29 C 193.49,129.26 194.17,137.35 191.51,155.77 C 195.77,161.43 200.32,161.07 193.53,182.01 C 189.75,193.67 194.52,212.78 181.15,213.27 C 180.88,217.36 180.39,234.98 180.26,237.9 C 179.42,257.21 146.12,287.53 139.59,292.78 C 132.36,298.59 118.61,301.09 114.29,301.98 C 109.96,302.88 100.65,302.79 96.32,301.98 C 91.98,301.17 77.85,295.71 70.03,290.62 C 56.1,281.56 30.91,253.27 31.34,239 C 31.43,236.08 30.72,217.35 30.45,213.27 C 18.32,211.74 22.6,193.45 18.15,182.01 C 11.71,165.45 15.61,160.61 19.87,154.94 C 17.19,136.52 18.08,129.26 20.55,114.29 Z" />
    </svg>
  </div>
}

export default Silhouette;