import abacus from './../../images/named/abacus.png';
import camera from './../../images/named/camera.png';
import clock from './../../images/named/clock.png';
import fruits from './../../images/named/fruits.png';
import man from './../../images/named/man.png';
import protest from './../../images/named/protest.png';
import question from './../../images/named/question.png';
import shoe from './../../images/named/shoe.png';
import stroller from './../../images/named/stroller.png';

import brodupproret from './../../images/named/brodupproret.png.jpg';
import fotografer from './../../images/named/fotografer.png.jpg';
import rosta from './../../images/named/rosta.png.jpg';
import nykterhet from './../../images/named/nykterhet.png.jpg';
import kvinnoarbete from './../../images/named/kvinnoarbete.png.jpg';
import barfotabarn from './../../images/named/barfotabarn.png.jpg';
import abort from './../../images/named/abort.png.jpg';
import sambokvinnor from './../../images/named/sambokvinnor.png.jpg';
import namninsamling from './../../images/named/namninsamling.png.jpg';

const imagesByName: {[id: string]: any} = {
  // Vägval illustrationer
  abacus,
  camera,
  clock,
  fruits,
  man,
  protest,
  question,
  shoe,
  stroller,
  // Inforutor
  brodupproret,
  fotografer,
  rosta,
  nykterhet,
  kvinnoarbete,
  barfotabarn,
  abort,
  sambokvinnor,
  namninsamling
}

const NamedImage = (props: { name?: string, className?: string, style?: any } ) => {
  if (props.name == undefined) {
    return null
  }
  let style: any = {
    mixBlendMode: "multiply"
  }
  if (props.style) {
    style = {
      ...style,
      ...props.style
    }
  }
  return <img style={style} className={props.className} src={imagesByName[props.name]} />
}

export default NamedImage;