import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { appConfig, isProductionBuild } from './app-config';

// Redirect to https in production, since the API can't be accessed
// when serving the frontend over http. Should be handled on the server
// ideally...
if (true || isProductionBuild) {
  if(window.location.protocol != 'https:') {
    console.log(window.location.protocol)
    window.location.href = window.location.href.replace("http://", "https://");
  }
}

axios.defaults.baseURL = appConfig.apiBaseUrl

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
