export interface IButtonProps {
  onClick: () => void
  title: string
  style?: any
  isDisabled?: boolean
}

const Button = (props: IButtonProps) => {
  var style = props.style ?? {}
  if (props.isDisabled) {
    style.opacity = 0.4
  }
  return <button
    className="button"
    style={style}
    onClick={() => {
      if (!props.isDisabled) {
        props.onClick()
      }
    }
    } >
    {props.title}
  </button>
}

export default Button