import { palette } from "../../theme"

const TimeoutIcon = (props: { color: string, relTimeRemaining: number }) => {
  const radius = 30
  const strokeWidth = 2 * radius
  const circumference = Math.round(2 * Math.PI * radius)
  const cx = strokeWidth + radius
  const cy = cx
  const width = 2 * strokeWidth + 2 * radius
  return <svg style={{
    transform: "rotate(-90deg)",
    strokeDasharray: circumference,
    strokeDashoffset: Math.round((1 - props.relTimeRemaining) * circumference)
    }} width={width} height={width}>
      <circle cx={cx} cy={cy} r={radius} strokeWidth={strokeWidth} fill="none" />
      <circle cx={cx} cy={cy} r={radius} stroke={props.color} strokeWidth={strokeWidth} fill="none" />
  </svg>
}

export default TimeoutIcon;