export const palette = {
  dimmedAlpha: 0.5,
  accent1: "rgb(79, 181, 181)",
  accent2: "rgb(244, 202, 112)",
  accent3: "rgb(225, 123, 151)",
  background: "rgb(249, 245, 232)",
  kioskBackground: "#c8ba93", // "#d1c7a5", // custom bg color for kiosk screen
  text: "rgb(58, 48, 44)",
  dimmedDark: "rgba(0, 0, 0, 0.4)",
  dimmedLight: "rgba(0, 0, 0, 0.1)"
}

export const teamColors = [
  palette.accent1,
  palette.accent2,
  palette.accent3
]
