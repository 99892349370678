import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import Button from "./Button";
import { useEffect, useState } from "react";
import Silhouette from "./Silhouette";
import { palette } from "../theme";
import LogoImage from './../images/logo_500.png'
import SplashHeads from './../images/splash_heads_small.png'

function Home() {
  const navigate = useNavigate();
  const location = useLocation()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [requestInProgress, setRequestInProgress] = useState(false)

  useEffect(() => {
    // Component mounted
    if (location.state) {
      setErrorMessage(location.state.errorMessage)
      // Clear state passed in the location so it doesn't persist after reload
      window.history.replaceState({}, document.title)
    }
    return () => {
      // Component unmounted
    }
  }, [])

  const startGame = (playerCount: number) => {
    setRequestInProgress(true)
    setErrorMessage(null)
    axios.post("/games", { playerCount, addPlayer: true })
      .then((response) => {
        // Response contains playerState and gameState
        // Navigate to the game and pass along this data
        navigate("/game", { state: response.data })
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
      .finally(() => {
        setRequestInProgress(false)
      })
  }

  return <div style={{
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }}
  >

    <img style={{ mixBlendMode: "multiply", width: "200px" }} src={SplashHeads} />
    <img style={{width: "280px", marginTop: "5px", marginBottom: "10px"}}  src={LogoImage} />
    <div style={{ fontWeight: "700", marginBottom: "20px", paddingBottom: "10px", borderBottom: "2px solid " + palette.dimmedLight }} >
      Vem ska Märit bli? Det bestämmer ni!
    </div>
    <div style={{ display: "flex", flexDirection: "column", maxWidth: "300px" }}>
      {[3, 6, 9].map((screenCount, index) => {
        return <Button key={index} isDisabled={requestInProgress} title={"Nytt spel (" + screenCount + " spelare)"} style={{ margin: "10px" }} onClick={() => startGame(screenCount)} />
      })}
    </div>
    <div style={{ position: "relative", width: "100%" }}>
      <p style={{ position: "absolute", width: "100%", textAlign: "center", paddingBottom: "30px" }} className="error">
        {errorMessage}
      </p>
    </div>
  </div>
}

export default Home