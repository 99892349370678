import { IGameState, IPlayerState } from "../../api-types"
import { GameStateUtil } from "../../store/game-state"
import { palette } from "../../theme"
import ProceedButtonOrSpinner from "./ProceedButtonOrSpinner"
import MustDecideImage from './../../images/mustdecide_small.png'

const RetryTurn = (props: { gameState: IGameState, playerState: IPlayerState, onProceed: () => void }) => {
  const isWaitingForOtherPlayers = GameStateUtil.playerIsWaitingForRetryTurn(props.gameState, props.playerState.playerId)

  return <div style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  }}>
    <img style={{ width: "200px", mixBlendMode: "multiply" }} src={MustDecideImage} />
    <h1 style={{
      margin: 0,
      marginBottom: "20px",
      paddingBottom: "10px",
      borderBottom: "1px solid " + palette.text
    }}>Märit måste <br></br>bestämma sig!</h1>
    <div style={{ width: "300px", marginBottom: "20px"}} >Ni har inte nått ett majoritetsbeslut. Det behöver ni för att gå vidare.</div>
    <ProceedButtonOrSpinner onProceed={() => props.onProceed()} showSpinner={isWaitingForOtherPlayers} />
  </div>
}

export default RetryTurn