import { IGameState } from "../api-types";

export const defaultGameState: IGameState = {
  id: "",
  teamScores: [],
  playerVotes: [],
  playerStates: [],
  introStepIndex: 0,
  turnOutroStepIndex: 0,
  turnIndex: 0,
  totalTurnCount: 0,
  turnRetryCount: 0,
  isKiosk: false
}

export class GameStateUtil {
  static allPlayersArePresent(gameState: IGameState): boolean {
    return gameState.playerStates.filter((playerState) => !playerState.isPresent).length == 0
  }

  static presentPlayerCount(gameState: IGameState): number {
    return gameState.playerStates.filter((playerState) => playerState.isPresent).length
  }

  static playerCount(gameState: IGameState): number {
    return gameState.playerStates.length
  }

  static teamCount(gameState: IGameState): number {
    return gameState.teamScores.length
  }

  static voteCountsPerTeam(gameState: IGameState): { count: number, relativeCount: number }[] {
    const teamCount = GameStateUtil.teamCount(gameState)
    const voteCountsByTeam: number[] = Array(teamCount).fill(0)
    gameState.playerVotes.forEach((vote) => {
      if (vote.teamIndex != null) {
        voteCountsByTeam[vote.teamIndex] += 1
      }
    })

    const maxVoteCount = GameStateUtil.playerCount(gameState)
    return voteCountsByTeam.map((count) => {
      return {
        count,
        relativeCount: count / maxVoteCount
      }
    })
  }

  static playersPerTeam(gameState: IGameState): number {
    return gameState.playerStates.length / gameState.teamScores.length
  }

  static playerIndex(gameState: IGameState, playerId: string): number {
    for (let i = 0; i < gameState.playerStates.length; i++) {
      if (gameState.playerStates[i]?.playerId == playerId) {
        return i
      }
    }

    return 0
  }

  static playerTeamIndex(gameState: IGameState, playerIndex: number): number {
    return Math.floor(playerIndex / GameStateUtil.playersPerTeam(gameState))
  }

  static playerHasRequestedNextIntroStep(gameState: IGameState, playerId: string): boolean {
    const playerIndex = this.playerIndex(gameState, playerId)
    return gameState.playerStates[playerIndex].hasRequestedNextIntroStep
  }

  static playerHasRequestedNextOutroStep(gameState: IGameState, playerId: string): boolean {
    const playerIndex = this.playerIndex(gameState, playerId)
    return gameState.playerStates[playerIndex].hasRequestedNextTurnOutroStep
  }

  static votingIsInProgress(gameState: IGameState): boolean {
    return gameState.playerVotes.filter((vote) => vote.teamIndex != null).length > 0
  }

  static allVotesHaveBeenCast(gameState: IGameState): boolean {
    return gameState.playerVotes.filter((vote) => vote.teamIndex == null).length == 0
  }

  static leadingOptionIndex(gameState: IGameState): number {
    const teamVoteCounts = Array(gameState.teamScores.length).fill(0)
    gameState.playerVotes.forEach((vote) => {
      if (vote.teamIndex != null) {
        teamVoteCounts[vote.teamIndex] += 1
      }
    })
    let maxCount = 0
    let maxIndex = 0
    teamVoteCounts.forEach((count, index) => {
      if (count > maxCount) {
        maxCount = count
        maxIndex = index
      }
    })
    return maxIndex
  }

  static maxRelVoteCount(gameState: IGameState): number {
    const playerCount = gameState.playerStates.length
    const teamVoteCounts = Array(gameState.teamScores.length).fill(0)
    gameState.playerVotes.forEach((vote) => {
      if (vote.teamIndex != null) {
        teamVoteCounts[vote.teamIndex] += 1
      }
    })

    return Math.max(...teamVoteCounts.map((count) => count / playerCount))
  }

  static playerHasVoted(gameState: IGameState, playerId: string): boolean {
    const playerIndex = GameStateUtil.playerIndex(gameState, playerId)
    return gameState.playerVotes[playerIndex].teamIndex != null
  }

  static playerIsWaitingAfterOutroStep(gameState: IGameState, playerId: string): boolean {
    const playerIndex = GameStateUtil.playerIndex(gameState, playerId)
    return gameState.playerStates[playerIndex].hasRequestedNextTurnOutroStep
  }

  static playerIsWaitingForRetryTurn(gameState: IGameState, playerId: string): boolean {
    const playerIndex = GameStateUtil.playerIndex(gameState, playerId)
    return gameState.playerStates[playerIndex].hasRequestedRetryTurn
  }

  static missingPlayerCount(gameState: IGameState): number {
    return gameState.playerStates.filter((state) => !state.isPresent).length
  }

  static gameIsCompleted(gameState: IGameState): boolean {
    return gameState.turnIndex == gameState.totalTurnCount
  }

  static currentTeamScores(gameState: IGameState): number[] {
    // A team's score is incremented on the backend when moving to the next turn.
    // This function returns scores that are also correct from when voting is completed
    // and the turn has finished.
    const scores = [...gameState.teamScores]
    if (GameStateUtil.allVotesHaveBeenCast(gameState) && GameStateUtil.maxRelVoteCount(gameState) > 0.5) {
      const winningOptionIndex = GameStateUtil.leadingOptionIndex(gameState)
      scores[winningOptionIndex] += 1
    }
    return scores
  }
}