import axios from "axios"
import { useContext, useEffect, useRef, useState } from "react"
import { setLightIntensities } from "../../actions/actions"
import { IGameState } from "../../api-types"
import { appConfig } from "../../app-config"
import { defaultGameState, GameStateUtil } from "../../store/game-state"
import { midiManager, store } from "../../store/store"
import { palette } from "../../theme"
import Button from "../Button"
import { kioskKeyCodes } from "./kiosk-key-codes"
import KioskGameInfo from "./KioskGameInfo"
import KioskIdle from "./KioskIdle"
import KioskStartGame from "./KioskStartGame"
import { lightsStateIdle, lightsStatePreGame, lightStateForGameState } from "./light-control"

export interface IKioskScreenProps {
  onDrumPadHit: () => void
}

export class DrumPadManager {
  onDrumPadHit: (() => void) | null = null
  constructor() {
    midiManager.onDrumPadHit = () => {
      if (this.onDrumPadHit != null) {
        this.onDrumPadHit()
      }
    }

    const onKeyDown = (e: KeyboardEvent) => {
      switch (e.code) {
        case kioskKeyCodes.button1: {
          if (this.onDrumPadHit != null) {
            this.onDrumPadHit()
          }
          break
        }
      }
    }

    document.addEventListener('keydown', onKeyDown)
  }
}

let pollIntervalHandle: any = null
let requestIsInProgress = false
const drumPadManager = new DrumPadManager()

enum KioskState {
  idle,
  creatingGame,
  gameInProgress
}

const KioskRoot = () => {
  const { state, dispatch } = useContext(store);
  const [kioskState, setKioskState] = useState(KioskState.idle)
  const [gameState, setGameState] = useState<IGameState | null>(null)
  const [errorMessage, setErrorMessage] = useState("")
  const prevGameState = useRef<IGameState | null>(null);

  const transitionToKioskState = (newState: KioskState) => {
    setKioskState(newState)
    if (newState != KioskState.idle) {
      setErrorMessage("")
    } else if (newState == KioskState.idle) {
      setGameState(null)
    }
  }

  useEffect(() => {
    if (gameState != null) {
      const lightState = lightStateForGameState(gameState)
      dispatch(setLightIntensities(lightState.lightIntensities))
    } else if (kioskState == KioskState.creatingGame) {
      dispatch(setLightIntensities(lightsStatePreGame.lightIntensities))
    } else {
      dispatch(setLightIntensities(lightsStateIdle.lightIntensities))
    }
    // Go back to idle if all players left
    if (prevGameState.current != null && gameState != null) {
      const prevCount = GameStateUtil.presentPlayerCount(prevGameState.current)
      const newCount = GameStateUtil.presentPlayerCount(gameState)
      // console.log({prevCount, newCount, players: gameState.playerStates})
      if (prevCount > 0 && newCount == 0) {
        transitionToKioskState(KioskState.idle)
      }
    }
    prevGameState.current = gameState
  }, [gameState, kioskState]);

  useEffect(() => {
    if (kioskState == KioskState.gameInProgress) {
      pollIntervalHandle = setInterval(() => {
        if (!requestIsInProgress) {
          requestIsInProgress = true
          // console.log("Polling")
          axios.get("games/" + gameState!.id)
          .then((response) => {
            setGameState(response.data.gameState)
          })
          .catch((error) => {
            if (error.response && error.response.status == 404) {
              setErrorMessage("Ogiltig spelomgång (" + error.message + ")")
              setKioskState(KioskState.idle)
            }
          }).finally(() => {
            requestIsInProgress = false
          })
        }
      }, appConfig.kioskPollIntervalMs)
    } else {
      // Stop polling
      clearInterval(pollIntervalHandle)
      requestIsInProgress = false
    }
  }, [ kioskState ])

  useEffect(() => {
    console.log("KioskRoot mounted")
    midiManager.startIfNotStarted({
      onDevicesChanged: () => {
        // Re-send light state after a delay, since the DMX dimmer does
        // not seem to be responsive immediately.
        setTimeout(() => {
          // console.log("Midi devices changed. Sending latest levels")
          midiManager.setLightIntensities(state.lightsState)
        }, 1000)
      },
      onMidiAccessDenied: (error) => { }
    })
    return () => {
      console.log("KioskRoot unmounted")
    }
  }, [])

  const renderContent = () => {
    switch (kioskState) {
      case KioskState.idle: {
        return <KioskIdle
          drumPadManager={drumPadManager}
          onStartGameCreation={() => {
            transitionToKioskState(KioskState.creatingGame)
          }}
        />
      }
      case KioskState.creatingGame: {
        return <KioskStartGame
          drumPadManager={drumPadManager}
          onCancel={() => {
            transitionToKioskState(KioskState.idle)
          }}
          onGameStartError={(error) => {
            setErrorMessage(error)
            transitionToKioskState(KioskState.idle)
          }}
          onGameStarted={(gameState) => {
            setGameState({ ...gameState })
            transitionToKioskState(KioskState.gameInProgress)
          }}
        />
      }
      case KioskState.gameInProgress: {
        return <KioskGameInfo
          gameState={gameState!}
          drumPadManager={drumPadManager}
          onCancel={() => transitionToKioskState(KioskState.idle) }
        />
      }
    }
  }

  return <div id="kiosk-root" style={{
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    flex: 1,
    width: "100%",
    position: "relative",
    backgroundColor: palette.kioskBackground
  }}>
    { renderContent() }
    <div style={{color: palette.dimmedDark, position: "absolute", left: "50%", bottom: "0%", transform: "translate(-50%, -50%)"}}>
      {errorMessage}
    </div>
  </div>
}

export default KioskRoot;